.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
}

.lang {
  width: 100%;
  height: 40px;
  line-height: 44px;
  text-align: right;

  :global(.ant-dropdown-trigger) {
    margin-right: 24px;
  }
}

.content {
  flex: 1;
  padding: 32px 0;
}

@media (min-width: 768px) {
  .container {
    background-image: url("./background.svg");
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }

  .content {
    padding: 32px 0 24px;
  }
}

.top {
  text-align: center;
}

.header {
  //height: 44px;
  //line-height: 44px;
  text-align: center;

  a {
    margin: auto;
    text-decoration: none;
  }
}

.logo {
  display: flex;
  justify-content: center;
}

.title {
  position: relative;
  top: 2px;
  font-weight: bold;
  font-size: 33px;
  font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.desc {
  margin-top: 24px;
  margin-bottom: 8px;
}

.main {
  width: 328px;
  margin: 40px auto;

  @media screen and (max-width: 576px) {
    width: 95%;
    max-width: 328px;
  }

  .icon {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.2);
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;
  }

  .other {
    margin-top: 24px;
    line-height: 22px;
    text-align: left;
    .register {
      float: right;
    }
  }
}

.tabsContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
