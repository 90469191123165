$light-header-background: #fafafa;
$gray-table: #bfbfbf;
$blue-table: #96d7fa;
$green-table: #7fd988;
$yellow-table: #ffe350;
$red-table: #dc5862;
$dark-table-background: #141414;

.pricing-table-row {
  &--remove-expand-button {
    .ant-table-row-expand-icon {
      display: none;
    }
  }

  &--highlight {
    background: rgba($blue-table, 0.3);

    td.ant-table-column-sort {
      background-color: rgba($blue-table, 0.1);
    }

    @at-root .ant-table-tbody tr#{&}:hover {
      background: rgba($blue-table, 0.4);

      td {
        background-color: transparent;
      }
    }
  }
}
