.filesCard {
  min-height: 10rem;
}

//.avatar-uploader {
//  .ant-upload.ant-upload-select-picture-card {
//    margin: 10px 10px;
//    width: 150px;
//    height: 150px;
//  }
//  .ant-upload.ant-upload-select-picture-card {
//    margin: 0;
//  }
//}
//

