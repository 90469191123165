.points-view {
  &__anchor-selector {
    position: sticky;
    top: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &--size-small {
    display: flex;
    flex-flow: column nowrap;

    .points-view__outer-jaw-container {
      overflow: auto;
    }

    .points-view__inner-jaw-container {
      display: flex;
      flex-flow: column nowrap;
      gap: 3rem;
      align-items: center;
      min-width: 500px;
      padding: 10px;

      svg {
        min-width: 500px;
        max-width: 650px;
      }
    }
  }

  &--size-large {
    display: flex;
    flex-flow: row nowrap;

    .points-view__outer-jaw-container {
      width: 100%;
      overflow: auto;
    }

    .points-view__inner-jaw-container {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      gap: 2rem;
      min-height: 500px;
      padding: 0 25px;

      svg {
        min-width: 500px;
        max-width: 650px;
      }
    }
  }

  &--readonly-large {
    display: flex;
    flex-flow: row nowrap;
    min-height: 200px;

    .points-view__outer-jaw-container {
      width: 100%;
      overflow: auto;
    }

    .points-view__inner-jaw-container {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      gap: 2rem;
      padding: 0;

      svg {
        width: 100%;
      }
    }
  }

  &--readonly-small {
    display: flex;
    flex-flow: row nowrap;
    min-height: 200px;

    .points-view__outer-jaw-container {
      width: 100%;
      overflow: auto;
    }

    .points-view__inner-jaw-container {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      padding: 0 0;

      svg {
        width: 100%;
      }
    }
  }
}
