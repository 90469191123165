.str-chat__message-replies-count-button {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-top: var(--xs-m);
  font-size: var(--sm-font);
  line-height: 15px;
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
  cursor: pointer;

  svg {
    fill: var(--grey-whisper);
    margin-right: var(--xxs-m);
  }
}

.dark.str-chat {
  .str-chat__message-replies-count-button {
    svg {
      fill: var(--white10);
    }
  }

  &.team {
    .str-chat__message-replies-count-button {
      svg {
        fill: var(--black40);
      }
    }
  }
}
