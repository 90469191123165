.headerInfo {
  position: relative;
  text-align: center;

  & > span {
    display: inline-block;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
  }

  & > p {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    line-height: 32px;
  }

  & > em {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 56px;
    background-color: #f0f0f0;
  }

  @media screen and (max-width: 576px) {
    margin-bottom: 16px;
    & > em {
      display: none;
    }
  }
}
