.str-chat__li {
  display: block;
  position: relative;

  .quoted-message {
    display: flex;
    align-items: flex-end;
    margin-bottom: var(--xxs-m);

    &-inner {
      display: flex;
      text-align: start;
      align-items: flex-end;
      background: var(--grey-whisper);
      border-radius: var(--border-radius-md) var(--border-radius-md) var(--border-radius-md) 0;
      max-width: 408px;
      padding: var(--xxs-p) var(--xs-p);

      .str-chat__message-attachment-card.str-chat__message-attachment-card--image {
        border: none;
        border-radius: var(--border-radius-md);
      }

      .str-chat__message-attachment {
        &--image:first-of-type {
          max-height: 48px;
          max-width: 48px;
          border-radius: var(--border-radius-md);
        }

        img {
          border-radius: var(--border-radius-md);
        }

        &--file:first-of-type {
          background: var(--white);
          border-radius: var(--border-radius-md);
          border-bottom: 1px solid var(--grey-whisper);
          min-width: 33%;
        }
      }

      :nth-child(2) {
        margin-left: var(--xs-m);
      }
    }

    &.mine {
      .quoted-message-inner {
        background: var(--white-snow);
      }
    }
  }

  /* group styling */
  &--top,
  &--single {
    margin: 24px 0 0;

    .str-chat__message {
      &-attachment--img,
      &-attachment-card,
      .str-chat__gallery {
        border-radius: var(--border-radius) var(--border-radius) var(--border-radius)
          calc(var(--border-radius-sm) / 2);
      }

      &.str-chat__message--has-text.str-chat__message--has-attachment {
        .str-chat__message-attachment--img,
        .str-chat__message-attachment-card {
          border-radius: var(--border-radius) var(--border-radius) var(--border-radius)
            calc(var(--border-radius-sm) / 2);
        }
      }

      &--me {
        text-align: right; // for inline(-flex/block) elements, e.g. gallery
        .str-chat__message {
          &-attachment--img,
          &-attachment-card {
            border-radius: var(--border-radius) var(--border-radius)
              calc(var(--border-radius-sm) / 2) var(--border-radius);
          }
        }

        &.str-chat__message--has-text.str-chat__message--has-attachment {
          .str-chat__message-attachment--img,
          .str-chat__message-attachment-card {
            border-radius: var(--border-radius) var(--border-radius)
              calc(var(--border-radius-sm) / 2) var(--border-radius);
          }
        }

        .str-chat__gallery {
          border-radius: var(--border-radius) var(--border-radius) calc(var(--border-radius-sm) / 2)
            var(--border-radius);
        }

        &.str-chat__message--has-text {
          .str-chat__gallery {
            border-radius: var(--border-radius) var(--border-radius)
              calc(var(--border-radius-sm) / 2) var(--border-radius);
          }
        }
      }
    }
  }

  &--middle {
    margin: 0;

    .str-chat__message {
      &-attachment--img,
      &-attachment-card,
      .str-chat__gallery {
        border-radius: calc(var(--border-radius-sm) / 2) var(--border-radius) var(--border-radius)
          calc(var(--border-radius-sm) / 2);
      }

      &.str-chat__message--has-text.str-chat__message--has-attachment {
        .str-chat__message-attachment--img,
        .str-chat__message-attachment-card,
        .str-chat__gallery {
          border-radius: calc(var(--border-radius-sm) / 2) var(--border-radius) var(--border-radius)
            calc(var(--border-radius-sm) / 2);
        }
      }

      &--me {
        .str-chat__message {
          &-attachment--img,
          &-attachment-card,
          .str-chat__gallery {
            border-radius: var(--border-radius) calc(var(--border-radius-sm) / 2)
              calc(var(--border-radius-sm) / 2) var(--border-radius);
          }
        }

        &.str-chat__message--has-text.str-chat__message--has-attachment {
          .str-chat__message-attachment--img,
          .str-chat__message-attachment-card,
          .str-chat__gallery {
            border-top-left-radius: calc(var(--border-radius-sm) / 2);
          }
        }
      }
    }
  }

  &--bottom {
    margin: 0 0 24px;

    .str-chat__message {
      &-attachment--img,
      &-attachment-card,
      .str-chat__gallery {
        border-radius: calc(var(--border-radius-sm) / 2) var(--border-radius) var(--border-radius)
          calc(var(--border-radius-sm) / 2);
      }

      &.str-chat__message--has-text.str-chat__message--has-attachment {
        .str-chat__message-attachment--img,
        .str-chat__message-attachment-card,
        .str-chat__gallery {
          border-radius: calc(var(--border-radius-sm) / 2) var(--border-radius) var(--border-radius)
            calc(var(--border-radius-sm) / 2);
        }
      }

      &--me {
        .str-chat__message {
          &-attachment--img,
          &-attachment-card,
          .str-chat__gallery {
            border-radius: var(--border-radius) calc(var(--border-radius-sm) / 2)
              calc(var(--border-radius-sm) / 2) var(--border-radius);
          }
        }

        &.str-chat__message--has-text.str-chat__message--has-attachment {
          .str-chat__message-attachment--img,
          .str-chat__message-attachment-card,
          .str-chat__gallery {
            border-top-left-radius: calc(var(--border-radius-sm) / 2);
          }
        }
      }
    }
  }

  &--single {
    margin-bottom: var(--md-m);
  }

  &--top,
  &--middle {
    .str-chat__message {
      &-data {
        display: none;
      }
    }
  }

  &--top {
    .str-chat__message {
      &-text {
        &-inner {
          border-radius: var(--border-radius) var(--border-radius) var(--border-radius)
            calc(var(--border-radius-sm) / 2);
        }
      }

      &--me {
        .str-chat__message-text {
          &-inner {
            border-radius: var(--border-radius) var(--border-radius)
              calc(var(--border-radius-sm) / 2) var(--border-radius);
          }
        }
      }
    }
  }

  &--single {
    .str-chat__message {
      &-text {
        &-inner {
          border-radius: var(--border-radius) var(--border-radius) var(--border-radius)
            calc(var(--border-radius-sm) / 2);

          &--has-attachment {
            border-radius: calc(var(--border-radius-sm) / 2) var(--border-radius)
              var(--border-radius) calc(var(--border-radius-sm) / 2);
          }
        }
      }

      &--me {
        .str-chat__message-text {
          &-inner {
            border-radius: var(--border-radius) var(--border-radius)
              calc(var(--border-radius-sm) / 2) var(--border-radius);

            &--has-attachment {
              border-radius: var(--border-radius) calc(var(--border-radius-sm) / 2)
                calc(var(--border-radius-sm) / 2) var(--border-radius);
            }
          }
        }
      }
    }
  }

  &--bottom,
  &--middle {
    .str-chat__message {
      &-text {
        &-inner {
          border-radius: calc(var(--border-radius-sm) / 2) var(--border-radius) var(--border-radius)
            calc(var(--border-radius-sm) / 2);
        }
      }

      &--me {
        .str-chat__message-text {
          &-inner {
            border-radius: var(--border-radius) calc(var(--border-radius-sm) / 2)
              calc(var(--border-radius-sm) / 2) var(--border-radius);

            &--has-attachment {
              margin: 0;
            }
          }
        }

        .str-chat__message-attachment-card {
          margin: 0;
          padding: 0;
          border-radius: var(--border-radius) calc(var(--border-radius-sm) / 2)
            calc(var(--border-radius-sm) / 2) var(--border-radius);
        }
      }
    }
  }
}

.str-chat__message,
.str-chat__message-simple {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0;
  position: relative;
  margin: calc(var(--xxs-m) / 2) 0;
  width: 100%;

  &--system {
    text-align: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: var(--xl-p);
    box-sizing: border-box;
    font-size: var(--xs-font);

    &__text {
      display: flex;
      align-items: center;
      width: 100%;

      p {
        margin: 0 24px;
        color: var(--grey);
        text-transform: uppercase;
        font-weight: var(--font-weight-bold);
      }
    }

    &__line {
      flex: 1;
      height: 1px;
      width: 100%;
      background-color: var(--overlay);
    }

    &__date {
      margin-top: 4px;
      text-transform: uppercase;
      color: var(--grey);
    }
  }

  &-inner {
    position: relative;

    @media screen and (min-width: 420px) {
      margin-right: 88px;
      margin-left: unset;
    }

    > .str-chat__message-simple__actions {
      position: absolute;
      top: 5px;
      left: calc(100% + 8px);
    }
  }

  &-attachment-container {
    display: flex;
    flex-direction: column;
  }

  &-text {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    position: relative;

    &-inner {
      position: relative;
      flex: 1;
      display: block;
      min-height: 32px;
      padding: var(--xxs-p) var(--xs-p);
      font-size: var(--lg-font);
      color: var(--black);
      border-radius: var(--border-radius) var(--border-radius) var(--border-radius) 0;
      background: var(--white-snow);
      border: 1px solid var(--border);
      margin-left: 0; /* set spacing when unfocused */

      p {
        /* Make sure really long urls and words don't break out.
        ** https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
        overflow-wrap: break-word;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }

      /* if text consists is focused */
      &--focused {
        background: var(--primary-color);
        border: 1px solid var(--primary-color);
        margin-right: 0;
        margin-left: 0; /* set spacing when focused */
      }

      &--has-attachment {
        border-radius: calc(var(--border-radius-sm) / 2) var(--border-radius) var(--border-radius)
          calc(var(--border-radius-sm) / 2);
      }

      /* if text consists of just one emoji */
      &--is-emoji {
        background: transparent;
        border: 1px solid transparent;
        font-size: var(--xxxl-font);

        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &-attachment {
    &--img {
      width: 100%;
      max-width: 480px;
      display: block;
      height: inherit;
      cursor: -moz-zoom-in;
      cursor: -webkit-zoom-in;
      cursor: zoom-in;
    }
  }

  &-data {
    margin-top: var(--xxs-m);
    width: 100%;
    font-size: var(--xs-font);
    color: var(--overlay-dark);
    position: absolute;
    white-space: nowrap;
  }

  &-name {
    font-weight: var(--font-weight-bold);
    margin-right: var(--xxs-m);
  }

  p {
    margin: 0;
    white-space: pre-line;
    line-height: 20px;

    &:not(:first-of-type) {
      margin: var(--sm-m) 0 0;
    }
  }

  /* me */
  &--me {
    display: flex;
    margin: var(--xxs-m) 0;
    justify-content: flex-end;

    .str-chat__message {
      &-text {
        display: flex;
        justify-content: flex-end;
      }

      &-attachment-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      &-inner {
        justify-content: flex-end;
        align-items: flex-end;
        @media screen and (min-width: 420px) {
          margin-right: unset;
          margin-left: 88px;
        }

        > .str-chat__message-simple__actions {
          position: absolute;
          top: 5px;
          left: unset;
          right: calc(100% + 8px);
        }
      }

      &-text-inner {
        flex: initial;
        background: var(--grey-whisper);
        border-color: transparent;
        text-align: right;
        border-radius: var(--border-radius) var(--border-radius) calc(var(--border-radius-sm) / 2)
          var(--border-radius);
        margin-right: 0; /* set spacing when unfocused */

        &--focused {
          background: var(--primary-color);
          border: 1px solid var(--primary-color);
          margin-left: 0;
          margin-right: 0; /* set spacing when focused */
        }

        &--has-attachment {
          border-radius: var(--border-radius) calc(var(--border-radius-sm) / 2)
            calc(var(--border-radius-sm) / 2) var(--border-radius);
        }

        &--is-emoji {
          background: transparent;
          border: 1px solid transparent;
          font-size: var(--xxxl-font);
          padding-left: 0;
          padding-right: 0;

          p {
            line-height: 48px;
          }
        }
      }

      &-attachment {
        &--img {
          width: 100%;
          max-width: 480px;
          display: block;
          height: inherit;
          object-fit: cover;
          border: none;
        }
      }

      &-data {
        text-align: right;
        right: 0;
        width: fit-content;
      }
    }
  }

  &--with-reactions {
    margin-top: var(--md-m);
  }

  &-link {
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    text-decoration: none;
  }

  &-mention {
    font-weight: var(--font-weight-bold);
  }

  &-url-link {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &--inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .messaging {
    &.str-chat {
      .str-chat {
        &__message,
        &__message--me {
          margin: calc(var(--xxs-m) / 2) 0;

          &--with-reactions {
            margin-top: var(--lg-m);
          }
        }

        &__message-attachment--image {
          margin: calc(var(--xxs-m) / 2) 0;
          max-width: 480px;
        }

        &__message-attachment--card {
          margin: calc(var(--xxs-m) / 2) 0;
          line-height: normal;
        }

        &__message-attachment-card {
          margin: calc(var(--xxs-m) / 2) auto;
          line-height: normal;
          background: var(--grey-gainsboro);

          &--title {
            color: var(--primary-color);
          }

          &--text {
            display: none;
          }

          &--url {
            text-transform: lowercase;
          }
        }

        &__message--deleted {
          margin: 0 var(--xl-m);
        }

        &__li {
          &--middle {
            .str-chat__message {
              .str-chat__message-attachment--card,
              .str-chat__message-attachment--image {
                border-top-left-radius: calc(var(--border-radius-sm) / 2);
              }
            }
          }
        } /* __li*/
      }
    }
  }

  /* deleted messages */
  &--deleted {
    margin: 0 0 0 var(--xl-m);
    flex-direction: column;
    align-items: flex-start;

    &-inner {
      background: var(--grey-whisper);
      padding: var(--xs-p) var(--sm-p);
      border-radius: var(--border-radius);
      font-size: var(--sm-font);
      color: var(--grey);
    }
  }

  &--me {
    &.str-chat__message--deleted {
      margin: 0 var(--xl-m) 0 0;
      align-items: flex-end;

      &-inner {
        background: var(--white-smoke);
        padding: var(--xs-p) var(--sm-p);
        border-radius: var(--border-radius);
        font-size: var(--sm-font);
        color: var(--grey);
      }
    }
  }

  /* error messages */
  &--error,
  &--failed {
    margin: 0 0 var(--lg-m) var(--xl-m);
    font-size: var(--sm-font);
    padding: var(--xxs-p) 0;

    .str-chat__message-text-inner {
      background: var(--faded-red);
      border: 1px solid var(--faded-red);
    }
  }

  &--me {
    &.str-chat__message--error,
    &.str-chat__message--failed {
      border-left: initial;
      margin-right: var(--xl-m);
    }
  }

  .str-chat__message-attachment-file--item:hover {
    background: transparent;
  }
}

.str-chat {
  &__message-simple {
    font-family: var(--second-font);

    &__actions {
      display: flex;

      &__action {
        margin: var(--xxs-m);
        display: flex;
        align-items: center;
        height: 10px;
        cursor: pointer;

        svg {
          fill: var(--black);
          opacity: 0.5;
        }

        &:hover {
          svg {
            opacity: 1;
          }
        }

        &--thread,
        &--reactions {
          display: none;
        }

        &--options {
          position: relative;
          display: none;
        }

        &--options.str-chat-angular__message-simple__actions__action--options--editing {
          position: relative;
          display: flex;
        }
      }
    }

    &:hover {
      .str-chat__message-simple__actions__action--thread {
        display: flex;
      }

      .str-chat__message-simple__actions__action--reactions {
        display: flex;
      }
    }

    &-text {
      display: flex;
      justify-content: flex-end;
      padding: 0;
      position: relative;
    }

    &-text-inner {
      flex: initial;
      text-align: left;
      max-width: 460px;

      &.str-chat__message-simple-text-inner--is-emoji {
        background: transparent;

        p {
          line-height: 48px;
        }
      }

      p {
        text-align: left;
      }

      a {
        color: var(--primary-color);
        font-weight: var(--font-weight-bold);
        text-decoration: none;
      }

      blockquote {
        margin: 0 0 0 var(--xxs-m);
        font-style: italic;
        padding-left: var(--md-p);
        position: relative;

        &::before {
          font-size: var(--xxl-font);
          content: '\201C';
          font-style: italic;
          position: absolute;
          opacity: 0.5;
          top: 2px;
          left: -5px;
        }
      }
    }

    &--me {
      .str-chat__message-simple-reply-button {
        display: flex;
        justify-content: flex-end;

        .str-chat__message-replies-count-button {
          display: flex;
          flex-direction: row-reverse;

          svg {
            transform: scaleX(-1);
            margin-left: var(--xxs-m);
            margin-bottom: var(--xxs-m);
            margin-right: 0;
          }
        }
      }

      .str-chat__message-simple__actions {
        justify-content: flex-end;
      }

      .str-chat__message-attachment--image {
        max-width: 460px;
      }

      &-text-inner {
        &--is-emoji {
          background-color: transparent;
          line-height: 32px;
        }
      }

      .str-chat__message-simple__actions {
        order: -1;
      }
    }

    &:hover {
      .str-chat__message-simple__actions__action--options {
        display: flex;
      }

      .str-chat__message-simple__actions__action--reactions {
        display: flex;
      }

      .str-chat__message-simple__actions__action--thread {
        display: flex;
      }

      @media screen and (max-width: 960px) {
        .str-chat__message-simple__actions__action--options {
          display: none;
        }

        .str-chat__message-simple__actions__action--reactions {
          display: none;
        }

        .str-chat__message-simple__actions__action--thread {
          display: none;
        }
      }
    }
  }
}

.str-chat__simple-message--error-message {
  text-align: left;
  text-transform: uppercase;
  font-size: var(--xs-font);
  opacity: 0.5;
}

.str-chat__message-simple-status {
  margin: var(--xs-m) 0 var(--xs-m) var(--xs-m);
  order: 3;
  position: absolute;
  left: 100%;
  bottom: 0;
  line-height: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;

  &-number {
    font-size: var(--xs-font);
    margin-left: 4px;
    position: absolute;
    left: 100%;
    color: var(--overlay-dark);
  }

  > .str-chat__avatar,
  > .str-chat-angular__avatar-host > .str-chat__avatar {
    align-self: flex-end;
    margin-right: 0;
  }

  > .str-chat__tooltip {
    display: none;
    max-width: 300px;
    min-width: 100px;
    text-align: center;
  }

  &:hover {
    > .str-chat__tooltip {
      display: block;
    }
  }

  &::after {
    position: absolute;
    bottom: 100%;
    right: 0;
    content: ' ';
    width: 15px;
    height: 15px;
  }
}

.str-chat__message-simple {
  .str-chat__message-attachment-card {
    margin: 0;
    background: var(--white);
    border: 1px solid var(--border);

    &--content {
      background: var(--grey-gainsboro);
    }

    &--text {
      display: none;
    }
  }

  .str-chat__message-attachment--file {
    margin: 0;
    background: var(--white);
    border-color: transparent;
    border: 1px solid var(--grey-gainsboro);
    border-radius: var(--border-radius-md);

    .str-chat__message-attachment-file--item {
      border-color: transparent;
      padding: 0 var(--xs-p);
    }

    &:first-of-type:not(.str-chat-angular__message-attachment-file-single) {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
      border-bottom: transparent;
    }

    &:last-of-type:not(.str-chat-angular__message-attachment-file-single) {
      border-top-color: transparent;
      border-radius: 0 0 var(--border-radius) calc(var(--border-radius-sm) / 2);

      &:first-of-type {
        border-bottom: 1px solid var(--grey-gainsboro);
        border-top: 1px solid var(--grey-gainsboro);
        border-radius: var(--border-radius) var(--border-radius) var(--border-radius)
          calc(var(--border-radius-sm) / 2);
      }
    }
  }

  .str-chat__message-attachment-file--item:not(.str-chat-angular__message-attachment-file-single) {
    border-radius: 0;
  }

  &--me {
    .str-chat__message-attachment-card {
      border-radius: var(--border-radius) var(--border-radius-sm) var(--border-radius-sm)
        var(--border-radius-sm);
    }

    .str-chat__message-attachment--file {
      background: var(--grey-gainsboro);

      &:last-of-type:not(.str-chat-angular__message-attachment-file-single) {
        border-radius: 0 0 calc(var(--border-radius-sm) / 2) var(--border-radius);

        &:first-of-type:not(.str-chat-angular__message-attachment-file-single) {
          border-radius: var(--border-radius) var(--border-radius) calc(var(--border-radius-sm) / 2)
            var(--border-radius);
        }
      }
    }
  }
}

.str-chat__list--thread {
  .str-chat__message-simple {
    &__actions {
      width: 30px;
    }

    &__actions__action--options {
      .str-chat__message-actions-box {
        right: unset;
        left: 100%;
        border-radius: var(--border-radius) var(--border-radius) var(--border-radius)
          calc(var(--border-radius-sm) / 2);
      }
    }
  }
}

.livestream.str-chat {
  .str-chat {
    &__li {
      &--single {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .str-chat__message-simple-text-inner {
    max-width: 218px;
  }

  .str-chat__message-simple-status {
    left: unset;
    right: 8px;
    bottom: 30px;
  }
}

.dark.str-chat {
  .str-chat__message-simple {
    &-text-inner {
      background: var(--white-smoke);
      color: var(--white);

      &--is-emoji {
        background: transparent;
      }
    }

    &__actions {
      svg {
        fill: var(--white);
      }
    }

    &-data {
      color: var(--white);
      opacity: 0.5;
    }

    .str-chat__message-attachment-card {
      background: transparent;

      &--content {
        background: var(--white-smoke);
      }

      &--url {
        color: var(--grey-gainsboro);
      }
    }

    .str-chat__message-attachment--file {
      border-color: transparent;
      background: var(--white-smoke);

      a,
      span {
        color: var(--white);
      }

      span {
        opacity: 0.5;
      }
    }

    .str-chat__message-simple-status {
      &-number {
        color: var(--grey-gainsboro);
      }
    }

    &--me {
      .str-chat__message-simple {
        &-text-inner {
          background: var(--black);

          &--is-emoji {
            background: transparent;
          }
        }

        .str-chat__message-attachment-card {
          &--content {
            background: var(--black);
          }
        }

        .str-chat__message-attachment--file {
          background: var(--overlay);
        }
      }
    }
  }
}

.dark.str-chat {
  .str-chat__message-simple__actions__action--options .str-chat__actions-box {
    background: var(--grey);
    box-shadow: 0 0 2px 0 var(--overlay), 0 1px 0 0 var(--border), 0 1px 8px 0 var(--border);
  }

  .str-chat__message {
    &--error,
    &--failed {
      .str-chat__message-simple-text-inner {
        background: var(--faded-red);
        border: 1px solid var(--faded-red);
      }
    }
  }
}

.str-chat__message {
  .str-chat__message-simple__actions__action--options {
    .str-chat__message-actions-box {
      left: 100%;
      right: unset;

      &--reverse {
        right: 100%;
        left: unset;
        border-radius: var(--border-radius) var(--border-radius) calc(var(--border-radius-sm) / 2)
          var(--border-radius);
      }
    }

    .str-chat__message-actions-box--mine {
      right: 100%;
      left: unset;
      border-radius: var(--border-radius) var(--border-radius) calc(var(--border-radius-sm) / 2)
        var(--border-radius);

      &.str-chat__message-actions-box--reverse {
        left: 100%;
        right: unset;
        border-radius: var(--border-radius) var(--border-radius) var(--border-radius)
          calc(var(--border-radius-sm) / 2);
      }
    }
  }

  a[href^='mailto:'] {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
  }
}

@media screen and (max-width: 375px) {
  .str-chat__message .str-chat__message-attachment--img {
    max-width: 235px;
  }
}

@media screen and (max-width: 414px) {
  .str-chat__message .str-chat__message-attachment--img {
    max-width: 274px;
  }
}

@media only screen and (device-width: 375px) and (-webkit-device-pixel-ratio: 3) {
  .str-chat__message .str-chat__message-attachment--img {
    max-width: 240px;
  }
}

.str-chat {
  // fixes the overall overflow/flex issues together with the rule below
  .mml-container {
    display: flex;
    padding-bottom: var(--xxs-p);
  }

  .mml-icon {
    font-family: 'Material Icons', sans-serif;
  }

  // fixes the overall overflow/flex issues together with the rule above
  .mml-wrap {
    display: block;
    max-width: none;
    // the max-width should match that to .str-chat__message-XXXX-text-inner
    max-width: 345px;

    @media screen and (max-width: 375px) {
      max-width: 220px;
    }
  }

  .mml-datepicker {
    min-width: 180px;
  }

  // undo the negative margins that make the mml content to expand to the
  // viewport edges
  .mml-align-right .mml-btnlist--floating,
  .mml-align-right .mml-carousel {
    margin-left: auto;
  }

  .mml-align-left .mml-btnlist--floating,
  .mml-align-left .mml-carousel {
    margin-right: auto;
  }

  @media screen and (max-width: 960px) {
    .mml-align-left .mml-carousel {
      margin-left: -10px;
    }

    .mml-align-right .mml-carousel {
      margin-right: -10px;
    }
  }

  // expand carousel to the edge of the screen, the horizontal padding is theme
  // specific in stream-chat-react
  &.messaging {
    // as .messaging.str-chat .str-chat__list:
    @media screen and (max-width: 960px) {
      .mml-align-left .mml-carousel {
        margin-left: -10px;
      }

      .mml-align-right .mml-carousel {
        margin-right: -10px;
      }
    }
  }
}

.str-chat__message-simple.mobile-press {
  .str-chat__message-simple__actions__action--options {
    display: flex;
  }

  .str-chat__message-simple__actions__action--reactions {
    display: flex;
  }

  .str-chat__message-simple__actions__action--thread {
    display: flex;
  }
}
