.str-chat__loading-channels {
  width: 300px;
  height: 100%;
  padding: var(--md-p);
  background: var(--grey-gainsboro);

  &-meta {
    flex: 1;
  }

  &-avatar,
  &-username,
  &-status {
    background-image: linear-gradient(
      -90deg,
      var(--bg-gradient-start) 0%,
      var(--bg-gradient-end) 100%
    );
  }

  &-username,
  &-status {
    border-radius: var(--border-radius);
    height: 14px;
  }

  &-avatar {
    width: 40px;
    height: 40px;
    border-radius: var(--border-radius-round);
    margin-right: var(--xs-m);
  }

  &-username {
    width: 40%;
    margin-bottom: var(--xs-m);
  }

  &-status {
    width: 80%;
  }

  &-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: var(--border-radius-sm);
    margin-bottom: var(--sm-m);
    animation: pulsate 1s linear 0s infinite alternate;

    &:nth-of-type(2) {
      animation: pulsate 1s linear 0.3334 infinite alternate;
    }

    &:last-of-type {
      animation: pulsate 1s linear 0.6667s infinite alternate;
    }
  }
}

@keyframes pulsate {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}
