.str-chat__virtual-list {
  // this resolves the issue with 1px tall messages (i.e. deleted messages)
  font-size: 0;

  .str-chat__virtual-list-message-wrapper {
    padding: 1px var(--xl-p);
    width: 100%;

    .str-chat__message-simple {
      padding-bottom: var(--sm-p);

      &.str-chat__virtual-message__wrapper--first {
        padding-bottom: 0;
        padding-left: var(--xl-p);

        .str-chat__avatar,
        .str-chat__message-data.str-chat__message-simple-data {
          display: none;
        }

        &.str-chat__message-simple--me {
          padding-right: var(--xl-p);
          padding-left: 0;

          &.str-chat__virtual-message__wrapper--end {
            padding-right: 0;
          }
        }

        &.str-chat__virtual-message__wrapper--end {
          padding-bottom: var(--xs-p);

          .str-chat__message-data.str-chat__message-simple-data {
            display: block;
            position: relative;
            width: 100%;
          }
        }
      }

      &.str-chat__virtual-message__wrapper--group {
        align-items: center;
        padding-bottom: 0;
        padding-left: var(--xl-p);

        &.str-chat__message-simple--me {
          padding-right: var(--xl-p);
          padding-left: 0;

          &.str-chat__virtual-message__wrapper--end {
            padding-right: 0;
          }
        }

        .str-chat__message-data.str-chat__message-simple-data {
          display: block;
          position: relative;
          width: 100%;
        }
      }

      &.str-chat__virtual-message__wrapper--end {
        padding-bottom: var(--xs-p);
        padding-left: 0;

        .str-chat__avatar,
        .str-chat__message-data.str-chat__message-simple-data {
          display: block;
        }
      }

      .quoted-message {
        display: flex;
        align-items: flex-end;
        margin-bottom: var(--xxs-m);

        &-inner {
          display: flex;
          text-align: start;
          align-items: flex-end;
          background: var(--grey-whisper);
          border-radius: var(--border-radius-md) var(--border-radius-md) var(--border-radius-md) 0;
          max-width: 408px;
          padding: var(--xxs-p) var(--xs-p);

          .str-chat__message-attachment-card.str-chat__message-attachment-card--image {
            border: none;
            border-radius: var(--border-radius-md);
          }

          .str-chat__message-attachment {
            &--image:first-of-type {
              max-height: 48px;
              max-width: 48px;
              border-radius: var(--border-radius-md);
            }

            img {
              border-radius: var(--border-radius-md);
            }

            &--file:first-of-type {
              background: var(--white);
              border-radius: var(--border-radius-md);
              border-bottom: 1px solid var(--grey-whisper);
              min-width: 33%;
            }
          }

          :nth-child(2) {
            margin-left: var(--xs-m);
          }
        }

        &.mine {
          .quoted-message-inner {
            background: var(--white-snow);
          }
        }
      }
    }
  }

  .str-chat__message.str-chat__message--deleted {
    align-items: initial;
    margin: 0;
  }

  .str-chat__typing-indicator {
    padding: 5px 40px; // important: div height should be big enough to fully contain the avatars
  }
}

.giphy-preview-message {
  background: var(--white-snow);
  border-top: 2px solid var(--border);
}

.str-chat__virtual-message__wrapper {
  display: flex;
  padding: 10px 40px;
  flex-direction: row;
  position: relative;

  &:hover {
    background: ghostwhite;
  }

  > .str-chat__avatar {
    margin: 0;
  }

  > .str-chat__virtual-message__content {
    padding-left: 12px;
  }

  .str-chat__avatar--rounded {
    border-radius: var(--border-radius);
  }

  .str-chat__avatar-fallback {
    background-color: lightslategray;
  }
}

.str-chat__virtual-message__meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.str-chat__virtual-message__data {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 70px;
  margin-left: auto;
}

.str-chat__virtual-message__author {
  color: var(--primary-color);
  font-size: 1rem;
}

.str-chat__virtual-message__date {
  font-size: 0.7rem;
  opacity: 0.5;
  padding-left: 8px;
  display: block;
  margin-left: auto;
}

.str-chat__virtual-message__actions {
  position: relative;
  cursor: pointer;

  svg {
    fill: var(--black);
    opacity: 0.5;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }

  .str-chat__message-actions-box {
    bottom: 10px;
    left: unset;
    right: 100%;
    border-radius: var(--border-radius) var(--border-radius) calc(var(--border-radius-sm) / 2)
      var(--border-radius);
  }
}

.str-chat__virtual-message__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;

  .str-chat__message--deleted-inner {
    padding: 8px 0;
  }
}

.str-chat__virtual-message__text {
  padding-top: 5px;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  word-break: break-word;

  p {
    padding-right: 16px;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: var(--font-weight-bold);
  }
}

.str-chat__virtual-message__wrapper--group {
  padding-top: 0;

  > .str-chat__avatar {
    display: none;
  }

  .str-chat__virtual-message__author {
    display: none;
  }

  .str-chat__virtual-message__content {
    padding-left: 50px;

    .str-chat__gallery {
      width: 100%;
      margin: 0;
      justify-content: unset;
    }
  }
}

.dark {
  .str-chat__virtual-message__wrapper {
    &:hover {
      background: var(--black20);
    }
  }

  .str-chat__virtual-message__text {
    color: ghostwhite;
  }

  .str-chat__virtual-message__date {
    color: var(--white);
  }
}
