.str-chat__input {
  background: var(--white);
  box-shadow: 0 -1px 3px 0 var(--border), 0 -1px 0 0 var(--border);
  display: flex;
  flex-wrap: wrap;
  padding: var(--xs-p);
  position: relative;
  font-family: var(--second-font);
  padding-bottom: 0;

  &--emojipicker {
    position: absolute;
    bottom: 100%;
    right: 0;
  }

  .rfu-file-previewer {
    flex: 1 1 100%;
  }

  &--textarea-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0;
    position: relative;

    .str-chat__textarea {
      textarea {
        padding-right: 72px;
      }
    }
  }

  &-emojiselect,
  .rfu-file-upload-button {
    position: absolute;
    cursor: pointer;
    width: 22px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-size: 44px 44px;
    fill: var(--black60);

    &:hover {
      fill: var(--black);
    }
  }

  &-emojiselect {
    right: 20px;
    top: calc(100% - 35px);
  }

  .rfu-file-upload-button {
    right: 46px;
    top: calc(100% - 34px);
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    font-size: var(--sm-font);
    background: var(--white);
    padding: 0 var(--xs-p) var(--xs-p) var(--xs-p);
    color: var(--lighten-black);

    &--typing {
      font-style: italic;
    }

    &--count {
      &--hidden {
        visibility: hidden;
      }
    }
  }
}

.str-chat__textarea {
  height: auto;
  flex: 1;

  textarea {
    width: 100%;
    outline: none;
    padding: var(--sm-p);
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: 20px;
    font-size: var(--lg-font);
    min-height: 42px;
    transition: height 100ms ease-in;
    resize: none;

    &:focus {
      background: var(--white);
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 2px var(--primary-color-faded);
    }

    &:placeholder {
      color: var(--black50);
    }
  }
}

.str-chat__emojisearch {
  bottom: calc(100%);
  left: 0;
  width: calc(100% - 20px);
  position: absolute;
  background: var(--white95);
  box-shadow: 0 0 1px 0 var(--black30), 0 0 6px 0 var(--black10);
  z-index: -1;
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
  margin: 0 var(--xs-m);
  max-height: 360px;
  overflow-y: auto;

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
  }
}

.dark.str-chat {
  .str-chat__emojisearch {
    background: var(--dark-grey);
    box-shadow: 0 0 1px 0 var(--black30), 0 0 6px 0 var(--black10);
    border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;

    .rta__list-header {
      background: var(--dark-grey);
      border: 1px solid var(--white5);
      box-shadow: 0 2px 1px 0 var(--black5);
      color: var(--white);
    }

    .rta__entity {
      color: var(--white);
    }
  }

  .rfu-file-previewer__file {
    a {
      color: var(--white);
    }

    &:hover {
      background: transparent;
    }
  }

  .rfu-file-previewer__close-button {
    color: var(--white);
  }
}

.rta {
  font-size: var(--md-font);
}

.rta__entity--selected {
  background-color: var(--primary-color);
  color: var(--white);
  width: 100%;
}

// Using separate rules for Angular so React SDK integrators don't have problems with "!important"
// Angular needs "!imporant" to override angular-mentions styles
.active.mention-active > a /* angular-mentions autocomplete */ {
  background-color: var(--primary-color) !important;
  color: var(--white) !important;
}

.rta__list {
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
}

.rta__list-header {
  padding: var(--sm-p);
  font-size: var(--md-font);
}

.str-chat__emoji-item {
  padding: 0 var(--md-p);
  display: flex;
  align-items: center;
  margin: 0 calc(var(--xs-m) * -1);

  &--entity {
    min-width: 24px;
  }

  &--highlight {
    font-weight: var(--font-weight-bold);
    font-size: var(--sm-font);
  }

  &--part {
    font-size: var(--sm-font);
  }
}

.str-chat__slash-command {
  padding: var(--xs-p) var(--sm-p);
  font-size: var(--md-font);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  br {
    display: none;
  }

  &-description {
    font-size: var(--sm-font);
  }
}

.str-chat__user-item {
  padding: var(--xs-p) var(--sm-p);
  display: flex;

  &--highlight {
    font-weight: var(--font-weight-bold);
    font-size: var(--sm-font);
  }

  &--part {
    font-size: var(--sm-font);
  }
}

.str-chat {
  .rfu-dropzone .rfu-dropzone__notifier {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: var(--xxs-p);
    z-index: 1001;
    display: none;
  }

  .rfu-dropzone--accept .rfu-dropzone__notifier {
    background: var(--primary-color-faded);
    display: block;
  }

  .rfu-dropzone--reject .rfu-dropzone__notifier {
    background: var(--red);
    display: block;
  }
}

.rfu-dropzone__inner {
  width: 100%;
  height: 100%;
  padding: 0 var(--lg-p);
  border: 1px dashed transparent;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--white);
  font-weight: 800;
  font-size: var(--sm-font);

  svg {
    display: none;
  }
}

.rfu-dropzone--reject .rfu-dropzone__inner {
  display: none;
}

.str-chat__file-uploads {
  max-height: 300px;
  flex: 1 1 100%;
  overflow-y: auto;
}

.rfu-file-upload-button svg {
  fill: var(--grey);
}

.dark.str-chat {
  .str-chat__input {
    background: var(--white5);
    box-shadow: 0 -1px 1px 0 var(--black30);

    &-footer {
      background: var(--white5);
    }

    textarea {
      background: var(--black10);
      border: 1px solid var(--black40);
      border-radius: var(--border-radius-sm);
      color: var(--white);
    }

    .str-chat__input-emojiselect {
      svg {
        fill: var(--white);
      }
    }

    .rfu-file-upload-button {
      svg {
        fill: var(--white);
      }
    }
  }
}

.str-chat__fileupload-wrapper {
  .str-chat__tooltip {
    display: none;
    bottom: 35px;
    right: 50px;
  }

  &:hover {
    .str-chat__tooltip {
      display: block;
    }
  }
}

.str-chat__emojiselect-wrapper {
  .str-chat__tooltip {
    display: none;
    bottom: 35px;
    right: 24px;
  }

  &:hover {
    .str-chat__tooltip {
      display: block;
    }
  }
}

.str-chat__small-message-input--textarea-wrapper {
  .str-chat__fileupload-wrapper {
    .str-chat__tooltip {
      bottom: 32px;
      right: 32px;
    }
  }

  .str-chat__emojiselect-wrapper {
    .str-chat__tooltip {
      bottom: 32px;
      right: 10px;
    }
  }

  .str-chat__input-small-cooldown {
    position: absolute;
    right: 20px;
    font-size: var(--lg-font);
    font-weight: var(--font-weight-bold);
    top: 6px;
  }
}
