.str-chat {
  &.messaging {
    .str-chat__load-more-button {
      &__button {
        border: 0;
        width: 100%;
        height: 40px;
        border-radius: var(--border-radius-md);
        background: var(--grey-whisper);
        box-shadow: 0 1px 5px 0 var(--border);
        color: var(--grey);
        padding: var(--xs-p);
        font-size: var(--md-font);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &.team {
    .str-chat__load-more-button {
      &__button {
        border: 0;
        background: transparent;
        width: 100%;
        height: 40px;
        padding: var(--xs-p);
        font-size: var(--md-font);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.str-chat__load-more-button__button {
  display: flex;
  justify-content: center;
}
