@import "src/styles/variables/breakpoints";
@import "src/styles/variables/colors";

.chat-page {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  max-height: calc(100vh - 64px);
  height: calc(100vh - 64px);
  background-color: $white;
  border-top: 1px solid rgba(0, 0, 0, 0.06);

  &--size-sm {
    .chat-page__channel-list-container {
      display: flex;
      min-width: 300px;
    }

    .str-chat-channel-list {
      .str-chat-channel-list {
        left: -100%;
        width: 100%;
      }
    }

    &.chat-page--channel-list-open {
      .str-chat-channel-list {
        left: 0;
        width: 100%;
      }
    }
  }

  &--size-md,
  &--size-lg {
    .chat-page__channel-list-container {
      display: flex;
      min-width: 330px;
    }

    .str-chat-channel-list {
      .str-chat-channel-list {
        //left: -100%;
        //width: 100%;
      }
    }
  }

  //@media #{$medium-up} {
  //  //grid-template: "cl cw";
  //  //grid-template-columns: minmax(330px, 400px) 1fr;
  //
  //  .str-chat-channel-list {
  //    min-width: 330px;
  //  }
  //
  //  .str-chat-channel {
  //    flex: 1;
  //  }
  //}

  //&__container {
  //  display: grid;
  //  flex: 1;
  //  height: 100%;
  //  width: 100%;
  //
  //  @media #{$medium-up} {
  //    grid-template: "cl cw";
  //    grid-template-columns: minmax(330px, 400px) 1fr;
  //  }
  //}

  //&__chat-list {
  //  grid-area: cl;
  //}
  //
  //&__chat-window {
  //  grid-area: cw;
  //}
}

//.chat-list {
//  &__header {
//    display: flex;
//    flex-flow: row nowrap;
//    justify-content: space-between;
//    margin-top: 1rem;
//    padding-left: 1rem;
//    padding-right: 1rem;
//    font-size: 26px;
//    color: $gray-9;
//  }
//
//  &__search-container {
//    margin-top: 1rem;
//    padding-left: 1rem;
//    padding-right: 1rem;
//  }
//
//  &__search-input-container {
//    background-color: #f2f3f7;
//    border-radius: 500px;
//  }
//
//  &__channels-label {
//    margin-top: 2rem;
//    margin-bottom: 0.6rem;
//    padding-left: 1rem;
//    padding-right: 1rem;
//    text-transform: uppercase;
//    font-size: 12px;
//    color: #b3b2b8;
//  }
//}
//
//.chat-page__chat-window {
//  border-left: 1px solid rgba(0, 0, 0, 0.06);
//}
//
//.chat-channel {
//  min-height: 60px;
//  display: flex;
//  flex-flow: row nowrap;
//  gap: 15px;
//  padding: 0.4rem 1rem;
//  cursor: pointer;
//
//  &:hover {
//    background-color: #f6f7fb;
//  }
//
//  &__avatar {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    min-width: 50px;
//    min-height: 50px;
//  }
//
//  &__section {
//    flex: 1;
//    display: flex;
//    flex-flow: column;
//    justify-content: space-around;
//    padding-top: 4px;
//    padding-bottom: 4px;
//  }
//
//  &__row {
//    display: flex;
//    flex-flow: row nowrap;
//    justify-content: space-between;
//  }
//
//  &__name {
//    font-size: 15px;
//    font-weight: bold;
//    color: #3e4146;
//  }
//
//  &__last-time {
//    color: #b3b2b8;
//    font-size: 11px;
//  }
//
//  &__message {
//    color: #b3b2b8;
//    font-size: 11px;
//  }
//
//  &__unread-count {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    color: white;
//    background-color: #fc4e57;
//    border-radius: 50%;
//    font-size: 10px;
//    min-width: 20px;
//    min-height: 20px;
//    font-weight: bold;
//  }
//}
