.cardsContainer {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  margin-top: 2rem;
}

.tableContainer {
  margin-top: 1.5rem;
}

.card {
  flex: 1;
  min-width: 300px;
}

.paymentCard {
  display: flex;
  flex-flow: row wrap;
  gap: 4rem;
}
