.str-chat__modal {
  background: var(--modal-overlay-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: none;
  align-items: center;
  justify-content: center;

  &--open {
    display: flex;
  }

  &__inner {
    max-width: 667px;
    background: var(--white);
    padding: var(--md-p);
    border-radius: var(--border-radius-md);
  }

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--md-p);
    font-size: var(--md-font);
    line-height: 10px;
    color: var(--white);
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      position: relative;
      top: 1px;
      margin-left: var(--xs-m);
      fill: var(--white);
    }

    &:hover {
      opacity: 0.79;
    }
  }

  .str-chat__edit-message-form {
    min-width: 300px;
  }

  .str-chat__input-emojiselect,
  .str-chat__input-fileupload {
    position: relative;
    top: unset;
    right: unset;
  }
}

.str-chat.dark.messaging {
  .str-chat__modal {
    &__inner {
      border: 2px solid var(--white);
      background: var(--black);
    }
  }
}

.str-chat__exit {
  display: flex;
  width: 86px;
  justify-content: space-between;
}
