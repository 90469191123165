// colors from: https://ant.design/docs/spec/colors
.ant-btn-primary:not([disabled]) {
  &.selected {
    background-color: $ant-blue-4;
    border-color: $ant-blue-4;
  }

  &[color="green"] {
    background-color: $ant-green-5;
    border-color: $ant-green-5;

    &.selected,
    &:hover,
    &:focus {
      background-color: $ant-green-4;
      border-color: $ant-green-4;
    }
  }

  &[color="yellow"] {
    background-color: $ant-gold-4;
    border-color: $ant-gold-4;

    &.selected,
    &:hover,
    &:focus {
      background-color: $ant-gold-5;
      border-color: $ant-gold-5;
    }
  }

  &[color="red"] {
    background-color: $ant-red-4;
    border-color: $ant-red-4;

    &.selected,
    &:hover,
    &:focus {
      background-color: $ant-red-5;
      border-color: $ant-red-5;
    }
  }
}

.ant-btn-default:not([disabled]) {
  &.selected {
    color: $ant-blue-4;
    border-color: $ant-blue-4;
  }

  &[color="green"] {
    &.selected,
    &:hover,
    &:focus {
      color: $ant-green-5;
      border-color: $ant-green-5;
    }
  }

  &[color="yellow"] {
    &.selected,
    &:hover,
    &:focus {
      color: $ant-gold-4;
      border-color: $ant-gold-4;
    }
  }

  &[color="red"] {
    &.selected,
    &:hover,
    &:focus {
      color: $ant-red-4;
      border-color: $ant-red-4;
    }
  }
}

.ant-btn:not(.ant-btn-primary):not(.ant-btn-default):not([disabled]) {
  @extend .ant-btn-default;
}
