$black: #000000;
$white: #ffffff;
$grey: #f0f0f0;

$dark-background: #262626;
$light-gray: #cccccc;

$header-local-light: #a6daa6;
$header-local-dark: #70c370;
$header-dev-light: #6fa3e2;
$header-dev-dark: #4b89d4;
$header-staging-light: #FFF276;
$header-staging-dark: #F0E04F;
$header-prod-light: #efa772;
$header-prod-dark: #cf7e43;

$light-header-background: #fafafa;
$gray-table: #bfbfbf;
$blue-table: #96d7fa;
$green-table: #7fd988;
$yellow-table: #ffe350;
$red-table: #dc5862;
$dark-table-background: #141414;

$dark-scrollbar: #434343;

$ant-blue-0: #e6f7ff;
$ant-blue-1: #bae7ff;
$ant-blue-2: #91d5ff;
$ant-blue-3: #69c0ff;
$ant-blue-4: #40a9ff;
$ant-blue-5: #1890ff;
$ant-blue-6: #096dd9;
$ant-blue-7: #0050b3;
$ant-blue-8: #003a8c;
$ant-blue-9: #002766;
$ant-cyan-0: #e6fffb;
$ant-cyan-1: #b5f5ec;
$ant-cyan-2: #87e8de;
$ant-cyan-3: #5cdbd3;
$ant-cyan-4: #36cfc9;
$ant-cyan-5: #13c2c2;
$ant-cyan-6: #08979c;
$ant-cyan-7: #006d75;
$ant-cyan-8: #00474f;
$ant-cyan-9: #002329;
$ant-geekblue-0: #f0f5ff;
$ant-geekblue-1: #d6e4ff;
$ant-geekblue-2: #adc6ff;
$ant-geekblue-3: #85a5ff;
$ant-geekblue-4: #597ef7;
$ant-geekblue-5: #2f54eb;
$ant-geekblue-6: #1d39c4;
$ant-geekblue-7: #10239e;
$ant-geekblue-8: #061178;
$ant-geekblue-9: #030852;
$ant-gold-0: #fffbe6;
$ant-gold-1: #fff1b8;
$ant-gold-2: #ffe58f;
$ant-gold-3: #ffd666;
$ant-gold-4: #ffc53d;
$ant-gold-5: #faad14;
$ant-gold-6: #d48806;
$ant-gold-7: #ad6800;
$ant-gold-8: #874d00;
$ant-gold-9: #613400;
$ant-green-0: #f6ffed;
$ant-green-1: #d9f7be;
$ant-green-2: #b7eb8f;
$ant-green-3: #95de64;
$ant-green-4: #73d13d;
$ant-green-5: #52c41a;
$ant-green-6: #389e0d;
$ant-green-7: #237804;
$ant-green-8: #135200;
$ant-green-9: #092b00;
$ant-grey-0: #a6a6a6;
$ant-grey-1: #999999;
$ant-grey-2: #8c8c8c;
$ant-grey-3: #808080;
$ant-grey-4: #737373;
$ant-grey-5: #666666;
$ant-grey-6: #404040;
$ant-grey-7: #1a1a1a;
$ant-grey-8: #000000;
$ant-grey-9: #000000;
$ant-lime-0: #fcffe6;
$ant-lime-1: #f4ffb8;
$ant-lime-2: #eaff8f;
$ant-lime-3: #d3f261;
$ant-lime-4: #bae637;
$ant-lime-5: #a0d911;
$ant-lime-6: #7cb305;
$ant-lime-7: #5b8c00;
$ant-lime-8: #3f6600;
$ant-lime-9: #254000;
$ant-magenta-0: #fff0f6;
$ant-magenta-1: #ffd6e7;
$ant-magenta-2: #ffadd2;
$ant-magenta-3: #ff85c0;
$ant-magenta-4: #f759ab;
$ant-magenta-5: #eb2f96;
$ant-magenta-6: #c41d7f;
$ant-magenta-7: #9e1068;
$ant-magenta-8: #780650;
$ant-magenta-9: #520339;
$ant-orange-0: #fff7e6;
$ant-orange-1: #ffe7ba;
$ant-orange-2: #ffd591;
$ant-orange-3: #ffc069;
$ant-orange-4: #ffa940;
$ant-orange-5: #fa8c16;
$ant-orange-6: #d46b08;
$ant-orange-7: #ad4e00;
$ant-orange-8: #873800;
$ant-orange-9: #612500;
$ant-purple-0: #f9f0ff;
$ant-purple-1: #efdbff;
$ant-purple-2: #d3adf7;
$ant-purple-3: #b37feb;
$ant-purple-4: #9254de;
$ant-purple-5: #722ed1;
$ant-purple-6: #531dab;
$ant-purple-7: #391085;
$ant-purple-8: #22075e;
$ant-purple-9: #120338;
$ant-red-0: #fff1f0;
$ant-red-1: #ffccc7;
$ant-red-2: #ffa39e;
$ant-red-3: #ff7875;
$ant-red-4: #ff4d4f;
$ant-red-5: #f5222d;
$ant-red-6: #cf1322;
$ant-red-7: #a8071a;
$ant-red-8: #820014;
$ant-red-9: #5c0011;
$ant-volcano-0: #fff2e8;
$ant-volcano-1: #ffd8bf;
$ant-volcano-2: #ffbb96;
$ant-volcano-3: #ff9c6e;
$ant-volcano-4: #ff7a45;
$ant-volcano-5: #fa541c;
$ant-volcano-6: #d4380d;
$ant-volcano-7: #ad2102;
$ant-volcano-8: #871400;
$ant-volcano-9: #610b00;
$ant-yellow-0: #feffe6;
$ant-yellow-1: #ffffb8;
$ant-yellow-2: #fffb8f;
$ant-yellow-3: #fff566;
$ant-yellow-4: #ffec3d;
$ant-yellow-5: #fadb14;
$ant-yellow-6: #d4b106;
$ant-yellow-7: #ad8b00;
$ant-yellow-8: #876800;
$ant-yellow-9: #614700;

$gray-1: #ffffff;
$gray-2: #fafafa;
$gray-3: #f5f5f5;
$gray-4: #f0f0f0;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-8: #595959;
$gray-9: #434343;
$gray-10: #262626;
$gray-11: #1f1f1f;
$gray-12: #141414;
$gray-13: #000000;
