.wrapper {
  display: flex;
  flex-flow: column nowrap;
  border-radius: 5px;
  border: 1px solid lightgrey;
  height: 220px;
  width: 220px;
  overflow: hidden;
  background-color: #fafafa;
}

.preview {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid lightgrey;
}

.extensionName {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
}

.description {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
  height: 60px;
  padding: 8px;
  background-color: #fafafa;

  .name {
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
  }
}

//.wrapper {
//  position: relative;
//  display: flex;
//  margin: 10px;
//  width: 150px;
//  height: 150px;
//  background: #f7f7f7;
//  &:hover {
//    cursor: pointer;
//
//    .fileTimeUpload {
//      color: #1890ff;
//      cursor: pointer;
//    }
//    .fileDesc p, .fileDesc .fileTimeUpload {
//      color: #1890ff;
//      cursor: pointer;
//    }
//    .fileIcon {
//      color: #1890ff;
//    }
//  }
//}
//
//.image {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  padding: 10px;
//  width: 100%;
//  overflow: hidden;
//}
//
//.fileExt {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  margin: 0 0 10px 0;
//  line-height: 1;
//  font-size: 30px;
//  transition: color .3s ease;
//  text-transform: uppercase;
//}
//
//.fileDesc {
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  p {
//    margin-bottom: 0;
//    font-size: 14px;
//    font-weight: 600;
//    transition: color .3s ease;
//
//  }
//  .fileTimeUpload {
//    color: #807878;
//    font-size: 12px;
//    font-weight: normal;
//    transition: color .3s ease;
//
//  }
//}
//
//.fileDelete {
//  position: absolute;
//  z-index: 9;
//  top: 10px;
//  right: 10px;
//  &:hover {
//    color: #1890ff;
//  }
//}
