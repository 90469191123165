.str-chat__channel-preview-messenger {
  display: flex;
  width: 100%;
  border: none;
  padding: var(--xs-p);
  align-items: center;
  background: transparent;
  text-align: left;
  margin: var(--xs-m) 0;
  color: var(--black);

  &:hover {
    cursor: pointer;
  }

  &--active {
    border: none;
    border-radius: var(--border-radius-md);
    background: var(--white-snow);
    box-shadow: 0 1px 5px 0 var(--border);
  }

  &--last-message p {
    font-size: var(--sm-font);
    margin: 0;
    line-height: 14px;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &--last-message {
    font-size: var(--sm-font);
    line-height: 14px;
    opacity: 0.5;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &--name {
    font-size: var(--md-font);
    line-height: 17px;
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: calc(var(--xxs-m) / 2);
    max-width: 250px;

    span {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &--unread {
    .str-chat__channel-preview-messenger--last-message p {
      opacity: 1;
      font-weight: var(--font-weight-semi-bold);
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .str-chat__channel-preview-messenger--last-message {
      opacity: 1;
      font-weight: var(--font-weight-semi-bold);
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.dark.str-chat {
  .str-chat__channel-preview-messenger {
    &--active {
      background: var(--white5);
      box-shadow: 0 1px 5px 0 var(--black10);
    }

    &--last-message p {
      color: var(--white);
      margin: 0;
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &--last-message {
      color: var(--white);
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &--name {
      color: var(--white);
    }
  }
}
