.letter-indicator {
  $size: 27px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: #bfbfbf;
  background-color: #f0f0f0;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.85) !important;
}
