%background-reaction-list {
  background: url('#{$assetsPath}/str-chat__reaction-list-sprite@1x.png') no-repeat;
  background-size: 59px 101px;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 2dppx) {
    background-image: url('#{$assetsPath}/str-chat__reaction-list-sprite@2x.png');
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 3),
    only screen and (min-resolution: 3dppx) {
    background-image: url('#{$assetsPath}/str-chat__reaction-list-sprite@3x.png');
  }
}

.str-chat__message,
.str-chat__message-commerce {
  .str-chat__reaction-list {
    @extend %background-reaction-list;

    position: absolute;
    right: 15px;
    top: -28px;
    z-index: 1;
    height: 33px;
    width: initial;
    background-position: 0 -66px;
    background-repeat: repeat-x;

    ul {
      position: relative;
      list-style-type: none;
      padding: 0;
      font-size: var(--lg-font);
      line-height: 26px;
      display: flex;
      justify-content: center;
      margin: -1px -16px 0 0;
      z-index: 888;
    }

    .emoji-mart-emoji {
      display: flex;
    }

    &::after,
    &::before {
      @extend %background-reaction-list;

      position: absolute;
      content: '';
      top: 0;
      height: 33px;
    }

    &::after {
      right: -26px;
      width: 26px;
      background-position: -33px -33px;
    }

    &::before {
      left: -13px;
      width: 13px;
      background-position: -0 -33px;
    }

    &--reverse {
      right: initial;
      left: 15px;
      position: absolute;

      ul {
        margin: -1px -4px 0 -16px;
      }

      &::after {
        right: -13px;
        width: 13px;
        background-position: -46px 0;
      }

      &::before {
        left: -26px;
        width: 26px;
        background-position: -0 0;
      }
    }

    li {
      display: flex;
      align-items: center;

      button {
        padding: 0;
      }
    }

    &--counter {
      color: var(--button-background);
      font-size: var(--sm-font);
    }
  }

  &--me,
  &--right {
    .str-chat__message-commerce-inner {
      > .str-chat__reaction-list {
        left: 46px;
      }
    }

    .str-chat__reaction-list {
      right: initial;
      left: 16px;

      ul {
        margin: -1px 0 0 -16px;
      }

      &::after {
        right: -13px;
        width: 13px;
        background-position: -46px 0;
      }

      &::before {
        left: -26px;
        width: 26px;
        background-position: -0 0;
      }

      &--reverse {
        right: 15px;
        left: initial;

        ul {
          margin: -1px -16px 0 0;
        }

        &::after {
          right: -26px;
          width: 26px;
          background-position: -33px -33px;
        }

        &::before {
          left: -13px;
          width: 13px;
          background-position: -0 -33px;
        }
      }
    }
  }
}

.str-chat__message-commerce {
  &-inner {
    > .str-chat__reaction-list {
      left: unset;
      right: 46px;
    }
  }

  &--right {
    .str-chat__message-commerce {
      &-inner {
        > .str-chat__reaction-list {
          right: unset;
          left: 46px;
        }
      }
    }
  }
}
