.str-chat__input-flat {
  background: var(--white);
  padding: var(--xs-p) var(--xl-p);
  position: relative;
  z-index: 1;
  width: 100%;

  &-quoted {
    border-top: 1px solid var(--black10);

    .quoted-message-preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: var(--xs-m);

      &-header {
        font-size: var(--md-font);
        margin-bottom: var(--xs-m);

        .str-chat__square-button {
          position: absolute;
          right: 0;
          top: 0;
          margin: unset;
          padding: var(--xs-p);
          border-radius: var(--border-radius);
          cursor: pointer;
        }
      }

      &-content {
        display: flex;
        align-items: flex-end;
        background: var(--grey-whisper);
        border-radius: var(--border-radius-md) var(--border-radius-md) var(--border-radius-md) 0;
        max-width: 60%;
        padding: var(--xs-p) var(--sm-p);

        &-inner {
          display: flex;
          text-align: start;
          align-items: flex-end;

          .str-chat__message-attachment {
            margin: 0;

            &--image {
              width: 48px;
            }

            &--img,
            &--card {
              border-radius: var(--border-radius-md);
              max-height: 48px;
              max-width: 48px;
              width: 100%;
            }

            &--file {
              min-width: 33%;
            }

            &-file {
              &--item {
                background: var(--white);
                border-radius: var(--border-radius-md);
                border-left: none;
                padding: 0 var(--xs-p);
              }
            }

            &-card--image {
              border: none;
              border-radius: var(--border-radius-md);
            }
          }

          :nth-child(2) {
            margin-left: var(--xs-m);
          }
        }
      }
    }
  }

  &-has-attachments {
    .str-chat__send-button {
      display: block;
    }
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .str-chat__textarea {
    flex: 1;
  }

  .str-chat__textarea > textarea {
    min-height: 56px;
    background: var(--grey-whisper);
    border-radius: var(--border-radius-md);
    padding: 20px 69px;
    font-size: var(--lg-font);
    line-height: 17px;
    border: none;
    margin: 0;

    &:focus {
      border: none;
      color: var(--black);
      border-radius: var(--border-radius-md);
      box-shadow: 0 0 0 3px var(--primary-color);
    }
  }

  &-footer {
    padding: var(--xs-p) var(--xl-p);
    background: var(--white);
  }

  &-emojiselect {
    position: absolute;
    top: calc(100% - 45px);
    left: 25px;

    svg {
      fill: var(--black);
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  &-cooldown {
    font-size: var(--xl-font);
    font-weight: var(--font-weight-bold);
  }

  &--textarea-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0;
    position: relative;

    .str-chat__fileupload-wrapper {
      .str-chat__tooltip {
        bottom: 45px;
        right: 25px;
      }
    }

    .str-chat__emojiselect-wrapper {
      .str-chat__tooltip {
        bottom: 50px;
        left: 32px;
        right: unset;

        &::after {
          left: 5px;
          right: unset;
        }
      }
    }
  }

  .str-chat__input--emojipicker {
    left: 0;
    right: auto;
  }

  .rfu-dropzone {
    width: 100%;
  }

  .rfu-file-previewer {
    width: 100%;
  }

  .rfu-file-upload-button {
    position: absolute;
    top: calc(100% - 40px);
    right: 25px;

    svg {
      fill: var(--black);
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.rfu-image-previewer,
.rfu-image-previewer-angular-host {
  flex: 1 1 100%;

  &__image {
    width: 60px !important;
    height: 60px !important;

    .rfu-thumbnail__wrapper {
      width: 60px !important;
      height: 60px !important;
      border-radius: var(--border-radius-md);

      .rfu-thumbnail__overlay,
      .rfu-icon-button {
        padding: 0;

        svg {
          opacity: 0.9;
          height: 25px;
          width: 25px;
        }
      }
    }
  }

  .rfu-thumbnail-placeholder {
    width: 60px;
    height: 60px;
    border-radius: var(--border-radius-md);
  }
}

.commerce.str-chat {
  .str-chat__input-flat {
    padding: 8px 24px;
    background: transparent;
  }
}

.dark.str-chat {
  .str-chat__input-flat {
    background: var(--white5);

    textarea {
      background: var(--white5);
      border-radius: var(--border-radius-md);
      color: var(--white);
    }

    &-emojiselect,
    .rfu-file-upload-button {
      svg {
        fill: var(--white);
      }
    }
  }

  &.commerce {
    .str-chat__input-flat {
      background: none;
    }
  }
}

@media screen and (max-width: 960px) {
  .str-chat.messaging {
    .str-chat__input-flat {
      padding: var(--xs-p) var(--xs-p);
    }
  }
}
