.str-chat__message-reactions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.str-chat__message-reactions-list-item {
  font-size: var(--xl-font);
  margin: 0 var(--xxs-m);
  position: relative;
  line-height: 0;

  button {
    padding: 0;
  }

  span[role='img'] {
    position: relative;
    display: block;
    top: -2px;
    transition: transform 100ms ease;

    &:hover {
      transform: scale(1.4);
    }
  }

  &__count {
    position: absolute;
    top: 28px;
    font-size: var(--xs-font);
    color: var(--white);
    font-weight: var(--font-weight-bold);
    left: 6px;
  }

  .latest-user {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -24px;
    left: 0;

    &-tooltip {
      display: none;
      text-align: center;
      position: absolute;
      bottom: calc(100% + 5px);
      left: 50%;
      transform: translate(-50%, 0);
      background: var(--black80);
      border-radius: var(--border-radius-sm);
      background-image: linear-gradient(-180deg, transparent, var(--black50));
      max-width: 237px;
      padding: var(--xxs-p) var(--xs-p);
      font-size: var(--sm-font);
      color: var(--white);

      &::after {
        content: '';
        position: absolute;
        top: calc(100% - 4px);
        left: 50%;
        transform: translate(-50%, 0) rotate(45deg);
        width: 7px;
        height: 7px;
        background-color: var(--dark-grey);
      }
    }

    &-not-found {
      border: 1.5px solid var(--white);
      border-radius: var(--border-radius-round);
      background-color: var(--secondary-color);
      width: inherit;
      height: inherit;
    }

    img {
      border: 1.5px solid var(--white);
      border-radius: var(--border-radius-round);
      object-fit: cover;
      width: inherit;
      height: inherit;
    }

    &:hover {
      .latest-user-tooltip {
        display: block;
      }
    }
  }
}

.str-chat__reaction-selector {
  z-index: 999;
  height: 60px;
  position: absolute;
  width: initial;
  background: var(--black);
  background-image: linear-gradient(-180deg, transparent, var(--border));
  border: 1px solid var(--white5);
  box-shadow: 0 3px 1px 0 var(--border), 0 11px 8px 0 var(--border);
  border-radius: var(--border-radius-round);
  display: flex;
  align-items: center;

  ul {
    position: relative;
    z-index: 1000;
    margin: 0 var(--sm-m);
  }

  &-tooltip {
    position: absolute;
    bottom: calc(100% + 15px);
    background: var(--black);
    border-radius: var(--border-radius-sm);
    min-width: 85px;
    min-height: 24px;
    max-width: 100%;
    padding: var(--xxs-p) var(--xs-p);
    font-size: var(--sm-font);
    color: var(--white);
    text-align: center;

    .arrow {
      position: absolute;
      top: calc(100% - 4px);
      left: 50%;
      transform: translate(-50%, 0) rotate(45deg);
      width: 7px;
      height: 7px;
      background-color: var(--black);
    }
  }

  .emoji-mart-emoji:hover {
    transition: 0.1s;
    transform: scale(1.2);
    cursor: pointer;
  }
}

.str-chat__message,
.str-chat__message-team,
.str-chat__message-simple,
.str-chat__message-commerce,
.str-chat__message-livestream {
  .str-chat__reaction-selector {
    top: -65px;
    left: 0;
  }
}

.str-chat__message-commerce {
  &--right {
    .str-chat__reaction-selector {
      left: unset;
      right: 0;
    }
  }
}

.str-chat__message-livestream {
  .str-chat__reaction-selector {
    left: unset;
    top: -70px;
    right: 0;
  }
}

.str-chat__message-team {
  .str-chat__reaction-selector {
    left: unset;
    top: -60px;
    right: 0;
  }
}

.str-chat__message-simple {
  .str-chat__reaction-selector {
    right: unset;
    left: 0;

    &--reverse {
      right: 0;
      left: unset;
    }
  }

  &--me {
    .str-chat__reaction-selector {
      left: unset;
      right: 0;

      &--reverse {
        right: unset;
        left: 0;
      }
    }
  }
}
