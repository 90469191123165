.str-chat__thread {
  background: var(--grey-whisper);
  flex: 1 0 300px;
  min-width: 300px;
  max-width: 300px;
  overflow-y: hidden;
  font-family: var(--second-font);
  overflow: hidden;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0;

  &--full {
    max-width: 100%;
  }

  &-header {
    position: relative;
    width: 100%;
    background: var(--grey-whisper);
    box-shadow: 0 1px 1px 0 var(--border);
    height: 70px;
    min-height: 70px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--sm-p);

    &-details {
      font-size: var(--md-font);

      small {
        display: block;
        font-size: var(--sm-font);
      }
    }
  }

  &-start {
    border-radius: var(--border-radius-sm);
    margin: var(--xs-m) var(--xs-m) 0 var(--xs-m);
    padding: var(--xs-p);
    background: var(--grey-whisper);
    text-align: center;
    font-size: var(--sm-font);
  }

  &-list {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-top: var(--sm-p);

    .str-chat__list {
      padding: 0;
    }

    .str-chat__message:first-of-type .str-chat__message-inner {
      margin-left: unset;
      margin-right: unset;
    }

    .str-chat__message-attachment.str-chat__message-attachment--file {
      border-radius: var(--border-radius-md);
      border-bottom: 1px solid var(--grey-whisper);
    }

    .quoted-message {
      display: flex;
      align-items: flex-end;
      margin-bottom: var(--xxs-m);

      &-inner {
        display: flex;
        text-align: start;
        align-items: flex-end;
        background: var(--grey-whisper);
        border-radius: var(--border-radius-md) var(--border-radius-md) var(--border-radius-md) 0;
        max-width: 408px;
        padding: var(--xxs-p) var(--xs-p);

        .str-chat__message-attachment.str-chat__message-attachment--file {
          display: none;
        }

        .str-chat__message-attachment-card.str-chat__message-attachment-card--image {
          border-radius: unset;
        }

        .str-chat__message-attachment.str-chat__message-attachment--card {
          border: none;
          border-radius: var(--border-radius-md);
        }

        .str-chat__message-attachment {
          &--image:first-of-type {
            max-height: 48px;
            max-width: 48px;
          }

          img {
            border-radius: var(--border-radius-md);
          }
        }

        :nth-child(2) {
          margin-left: var(--xs-m);
        }
      }
    }

    .quoted-message.mine {
      .quoted-message-inner {
        background: var(--white);
      }
    }
  }
}

.messaging {
  &.str-chat {
    .str-chat {
      &__thread {
        margin-right: var(--xs-m);
        margin-top: var(--sm-m);
        border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
        overflow: hidden;
        background: var(--white);
        max-height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 0;

        &.str-chat__thread--full {
          margin: 0;
        }

        .str-chat__gallery {
          justify-content: flex-end;
          border-radius: 0;

          &-image,
          &-placeholder {
            width: 100px;
            height: 100px;
          }
        }
      }

      &__thread-list {
        padding: var(--sm-p) var(--sm-p) 0 var(--sm-p);

        > .str-chat__list {
          background: transparent;
          padding: 0;

          .str-chat__list--thread {
            padding: 0;
          }
        }
      }

      &__thread-header {
        border: none;
        // box-shadow: none;
        background: var(--white);
        box-shadow: 0 2px 9px 0 var(--border), 0 1px 0 0 var(--border);
      }

      &__thread-start {
        margin-top: var(--md-m);
      }

      @media screen and (max-width: 960px) {
        &__thread {
          position: fixed;
          top: 0;
          right: 0;
          height: 100vh;
          background: var(--white);
          z-index: 1000;
          margin: 0;
          width: 100vw;
          max-width: 100%;
        }
      }
    }

    &.dark {
      .str-chat__thread {
        background: var(--white5);

        &-header {
          background: var(--black80);
          box-shadow: 0 7px 9px 0 var(--black5), 0 1px 0 0 var(--black5);
          color: var(--white);
        }

        &-start {
          color: var(--white);
        }
      }

      @media screen and (max-width: 960px) {
        .str-chat__thread {
          background: var(--dark-grey);
        }
      }
    }
  }
}

.team {
  &.str-chat {
    .str-chat {
      &__thread {
        background: var(--white);
      }

      @media screen and (max-width: 960px) {
        &__thread {
          position: fixed;
          top: 0;
          right: 0;
          height: 100vh;
          background: var(--white);
          z-index: 1000;
          margin: 0;
          width: 100vw;
          max-width: 100%;
        }
      }

      &__thread-header {
        height: 80px;
      }
    }

    &.dark {
      .str-chat__thread {
        background: var(--dt-bg-team);
      }
    }
  }
}

.livestream.str-chat,
.str-chat {
  .str-chat__thread {
    background: transparent;

    &-header {
      background: var(--white30);
    }
  }

  .str-chat__list--thread {
    padding: 0 var(--xs-p);
  }

  &.dark {
    .str-chat__thread {
      background: var(--dark-grey);

      &-header {
        background: var(--white5);
        box-shadow: 0 1px 1px 0 var(--black30);
        color: var(--white);
      }

      &-start {
        background: var(--black5);
        border-radius: var(--border-radius-sm);
        color: var(--white);
      }
    }
  }
}
