.anchor-type-icon {
  width: 42px;
  height: 42px;
  position: relative;

  &--is-selected {
    path {
      fill: #1890ff;
    }
  }

  &__base {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    &--pontic {
      bottom: 11%;
      width: 55%;
    }

    &--prepared-tooth {
      bottom: 0;
      width: 58%;
    }

    &--implant {
      bottom: 0;
      width: 58%;
    }
  }

  &__accent {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    &--glue {
      top: 0;
      width: 37%;
    }

    &--screw {
      top: 3%;
      width: 30%;
    }
  }
}
