.pricing-template {
  display: grid;
  grid-template-columns: minmax(140px, auto) min-content;
  gap: 10px 20px;

  &__label {
    overflow-wrap: break-word;
  }

  &__price {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }
}
