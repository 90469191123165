.order-entry {
  &__outer-container {
    width: 100%;
  }

  &__inner-container {
    display: grid;
    width: 100%;

    &--size-large {
      grid-template:
        "name  .      btn    delete" auto
        "spec  .      btn    delete" auto
        "price price  price  price " auto /
        auto 1fr auto auto;

      grid-gap: 0.5rem 1rem;
    }

    &--size-small {
      grid-template:
        "name  ." auto
        "spec  ." auto
        "price ." auto
        "btn   delete" auto /
        auto auto;

      grid-gap: 0.5rem 1rem;
    }
  }

  &__name {
    grid-area: name;
  }

  &__spec {
    grid-area: spec;
  }

  &__btn {
    grid-area: btn;
    align-self: center;
  }

  &__price {
    grid-area: price;
  }

  &__delete {
    grid-area: delete;
    align-self: center;
  }
}
