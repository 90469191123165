.str-chat__message-attachment-card {
  position: relative;
  background: var(--white);
  border-radius: var(--border-radius) var(--border-radius) var(--border-radius) 0;
  overflow: hidden;
  font-size: var(--sm-font);
  border: 10px solid var(--border);
  margin: var(--lg-m) 0 0 0;

  &--header {
    width: 100%;
    height: 175px;

    img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  &--title {
    font-weight: var(--font-weight-bold);
    flex: 1;
  }

  &--flex {
    min-width: 0;

    &,
    > * {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--content {
    padding: var(--xs-p) var(--sm-p);
    margin: calc(var(--xs-m) * -1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > * {
      margin: var(--xs-m) 0;
    }
  }

  &--url {
    text-decoration: none;
    display: block;
    color: var(--black);
    text-transform: uppercase;
    opacity: 0.5;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &--giphy &--header {
    height: unset;
  }
}

.str-chat.commerce {
  .str-chat__message-attachment-card {
    max-width: 375px;
    width: 100%;
  }
}

.str-chat__message--me {
  .str-chat__message-attachment-card {
    background: var(--overlay);
    border: 1px solid transparent;
    border-radius: var(--border-radius) var(--border-radius) 0 var(--border-radius);
  }
}

.dark.str-chat {
  .str-chat__message-attachment-card__giphy-logo {
    filter: invert(100%);
  }
}

.str-chat__message-attachment-card.str-chat__message-attachment-card--giphy {
  .str-chat__message-attachment-card--content {
    background-image: url('#{$assetsPath}/Poweredby_100px-White_VertText.png');
    background-position: right var(--border-radius) bottom var(--border-radius);
    background-repeat: no-repeat;
    /* that was the original sizing of the img in React */
    background-size: 56px;
  }
}
