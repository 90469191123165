@import "styles/abstract/variables.scss";

.userMenu {
  grid-area: user;
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media #{$small-down} {
    justify-self: end;
  }
}

$avatar-frame: 4px;

.avatarContainer {
  display: inline-flex;
  position: relative;
  margin: -$avatar-frame ($avatar-frame + 12px) -$avatar-frame -$avatar-frame;

  &:before {
    content: "";
    position: absolute;
    top: -$avatar-frame;
    bottom: -$avatar-frame;
    left: -$avatar-frame;
    right: -$avatar-frame;
    background-color: white;
    border-radius: 50%;
  }
}

.name {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-right: 1rem;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;

  @media #{$small-down} {
    max-width: 120px;
  }
}
