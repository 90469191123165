%scrollable {
  overflow-x: hidden;
  overflow-y: auto;
}

.str-chat-angular__message-list-host {
  @extend %scrollable;
}

.str-chat__list {
  @extend %scrollable;
  flex: 1;
  -webkit-overflow-scrolling: touch; /* enable smooth scrolling on ios */
  padding: 0;

  .str-chat__reverse-infinite-scroll {
    padding-top: 72px;
  }

  &-wrapper {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &--thread {
    padding: var(--sm-p) 0 0 0;
    overflow: visible;
  }

  &__center {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.str-chat__ul {
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.str-chat__custom-notification {
  border-radius: var(--border-radius-sm);
  font-size: var(--sm-font);
  padding: var(--xs-p);
  font-family: var(--second-font);
  text-align: center;

  &.notification-success {
    background: var(--faded-green);
    border: 1px solid var(--green);
    color: var(--green);
  }

  &.notification-error {
    background: var(--faded-red);
    border: 1px solid var(--red);
    color: var(--red);
  }
}

.str-chat__connection-issue {
  background: var(--faded-red);
  border: 1px solid var(--red);
  color: var(--red);
  border-radius: var(--border-radius-sm);
  font-size: var(--sm-font);
  padding: var(--xs-p);
  font-family: var(--second-font);
  text-align: center;
}

.str-chat__list-notifications {
  padding: 0 var(--xl-p);
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
}

.messaging {
  &.str-chat {
    .str-chat {
      &__list {
        padding: 0 var(--xl-p) 0;
        background: var(--white);

        .str-chat__reverse-infinite-scroll {
          padding-top: 56px;
        }

        &-notifications {
          background: var(--white);
        }
      }
    }
    @media screen and (max-width: 960px) {
      .str-chat {
        &__list {
          padding: 0 var(--xs-p) 0;

          .str-chat__reverse-infinite-scroll {
            padding-top: 56px;
          }

          &-notifications {
            padding: 0 var(--xs-p);
          }
        }
      }
    }

    &.dark {
      .str-chat__list {
        background: var(--white5);

        &-notifications {
          background: var(--white5);
        }
      }
    }
  }
}

.livestream {
  &.str-chat {
    .str-chat {
      &__list {
        padding: 0 var(--xs-p);

        .str-chat__reverse-infinite-scroll {
          padding-top: 56px;
        }
      }
    }
  }
}

.commerce {
  &.str-chat {
    .str-chat {
      &__list {
        padding: 0 var(--md-p) 0;

        .str-chat__reverse-infinite-scroll {
          padding-top: 72px;
        }

        &-notifications {
          padding-left: var(--md-p);
          padding-right: var(--md-p);
        }
      }
    }
  }
}

.str-chat__virtual-list {
  position: relative;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* enable smooth scrolling on ios */
  background: var(--white);
  margin: 0;
  width: 100%;
  height: 100%;

  // conditionally showing the header displaces items when prepending.
  // a simple workaround is to make the loading indicator an overlay.
  &__loading {
    display: flex;
    padding-top: var(--xs-p);
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  p {
    margin: 0 !important; // always use padding, margin mess up the height calculations

    a {
      white-space: pre-line;
      overflow: hidden;
      word-wrap: break-word;
    }
  }
}

.dark .str-chat__virtual-list {
  background: var(--white5);
}
