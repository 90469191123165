@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,400i,700,700i');

.str-chat {
  box-sizing: border-box;

  *,
  *::after,
  *::before {
    box-sizing: inherit;
    font-family: var(--second-font);
  }
}

.clearfix {
  clear: both;
}

// button {
//   cursor: pointer;
//   background-color: transparent;
//   border: none;
//   padding: 0;
//   display: flex;
//   align-items: center;
//   width: auto;
// }

.messenger-chat {
  &.str-chat {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: var(--second-font);
    height: calc(100vh - 64px);
    margin: 0;
    flex: 1 0 100%;

    .str-chat {
      &__container {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: row;
      }

      &__main-panel {
        width: 100%;
        min-width: 250px;
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: var(--sm-p) var(--sm-p) 0 var(--xs-p);
      }
    }
  }
}

.str-chat {
  font-family: var(--second-font);
  color: var(--black);

  &.messaging,
  &.commerce {
    background-color: var(--grey-2);
    width: 100%;

    &.dark {
      background-color: var(--dark-grey);
    }
  }

  &.team {
    &.dark {
      background: var(--dark-grey);
    }
  }

  &.livestream {
    &.dark {
      background: var(--dark-grey);
    }
  }
}

/* declare a font faces for our Emoji Replacement font, based on the default font used by Stream Chat React */

$emoji-flag-unicode-range: U+1F1E6-1F1FF;

/* png based woff for most browsers */
@font-face {
  font-family: ReplaceFlagEmojiPNG;
  src: url('../assets/NotoColorEmoji-flags.woff2') format('woff2');
  /* using the unicode-range attribute to limit the reach of the Flag Emoji web font to only flags */
  unicode-range: $emoji-flag-unicode-range;
}

/* svg based for firefox */
@font-face {
  font-family: ReplaceFlagEmojiSVG;
  src: url('../assets/EmojiOneColor.woff2') format('woff2');
  unicode-range: $emoji-flag-unicode-range;
}

.str-chat--windows-flags {
  .str-chat__textarea__textarea,
  .str-chat__message-text-inner *,
  .str-chat__emoji-item--entity,
  .emoji-mart-emoji-native * {
    font-family: ReplaceFlagEmojiPNG, var(--second-font), sans-serif;
    font-display: swap;
  }
}

@-moz-document url-prefix('') {
  .str-chat--windows-flags {
    .str-chat__textarea__textarea,
    .str-chat__message-text-inner *,
    .str-chat__emoji-item--entity,
    .emoji-mart-emoji-native * {
      font-family: ReplaceFlagEmojiSVG, var(--second-font), sans-serif;
      font-display: swap;
    }
  }
}


.str-chat-channel {
  max-height: 100vh;
  width: 100%;

  .str-chat__container {
    height: 100%;
    display: flex;

    .str-chat__main-panel,
    .str-chat-angular__main-panel-inner {
      height: calc(100vh - 64px);
      width: 100%;
      min-width: 250px;
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .rfu-dropzone {
      width: 100%;
    }
  }

  &.team {
    .str-chat {
      &__container {
        display: flex;
      }
    }
  }

  &.commerce {
    .str-chat {
      &__main-panel {
        width: 100%;
      }

      &__container {
        background: var(--white95);
      }
    }

    &.dark {
      .str-chat__container {
        background: var(--dark-grey);
        box-shadow: 0 10px 31px 0 var(--black50);
      }
    }
  }
}

.str-chat.dark {
  .emoji-mart {
    background: var(--dark-grey);
    border: var(--dark-grey);
  }

  .emoji-mart-category-label span {
    background: var(--dark-grey);
    color: var(--white);
  }

  .emoji-mart-search {
    input {
      background: var(--dark-grey);
      color: var(--white);
    }

    button svg {
      fill: var(--white);
    }
  }
}
