.str-chat__message-commerce {
  display: flex; /* inline-flex if you don't want the  */
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 0 0 0;
  position: relative;
  margin: 1px 0;
  &-inner {
    position: relative;
    /* flex: 1 if you want the messagebubbles to be the same width- */
    > .str-chat__message-commerce__actions {
      min-height: 10px;
      min-width: 30px;
      float: right;
      .str-chat__reaction-list {
        left: unset;
        right: 46px;
      }
    }
  }

  .str-chat__avatar {
    margin-right: 8px;
  }

  .str-chat__message-attachment {
    &-card {
      &--content {
        margin: 0;
        padding: 6px 8px;
      }
    }
  }

  &--top,
  &--middle {
    margin-left: 40px;

    .str-chat__message-commerce {
      &-data {
        display: none;
      }
    }
  }

  &--top {
    .str-chat__message-commerce {
      &-text {
        &-inner {
          border-radius: 16px 16px 4px 4px;
        }
      }
    }
  }

  &--bottom {
    .str-chat__message-commerce {
      &-text {
        &-inner {
          border-radius: 4px 4px 16px 0;
        }
      }
    }
  }

  &--single {
    .str-chat__message-commerce {
      &-text {
        &-inner {
          border-radius: 16px 16px 16px 0;
          &--has-attachment {
            border-radius: 4px 4px 16px 0;
          }
        }
      }
    }
  }

  &--middle {
    .str-chat__message-commerce {
      &-text {
        &-inner {
          border-radius: 4px 4px 4px 4px;
        }
      }
    }
  }

  &-text {
    display: flex;

    padding: 0 0 0 0;
    position: relative;

    &-inner {
      position: relative;
      display: block;
      min-height: 32px;
      padding: 5px 10px;
      font-size: var(--lg-font); /* variables */
      color: black; /* variables */
      border-radius: var(--border-radius) var(--border-radius) var(--border-radius) 0;
      background: #fff;
      border: 1px solid var(--border-color);

      margin-left: 0;
      max-width: 345px;
      p {
        margin: 0;
        white-space: pre-line;
        &:not(:first-of-type) {
          margin: 16px 0 0;
        }

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }

      &--has-attachment {
        border-radius: 2px var(--border-radius) var(--border-radius) 2px;
      }

      &--is-emoji {
        background: transparent;
        border: 1px solid transparent;
        font-size: 32px;
        line-height: 48px;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &-attachment {
    &--img {
      width: 100%;
      max-width: 480px;
      display: block;
      height: inherit;
      cursor: -moz-zoom-in;
      cursor: -webkit-zoom-in;
      cursor: zoom-in;
    }
  }

  &-data {
    margin-top: 5px;
    width: 100%;
    font-size: var(--xs-font);
    color: rgba(0, 0, 0, 0.5);
  }

  &-name {
    font-weight: var(--font-weight-bold);
    margin-right: 5px;
  }

  p {
    margin: 0;
    line-height: 20px;
  }

  &--with-reactions {
    margin-top: 30px;
    .str-chat__message-commerce__actions__action--reactions {
      display: none;
    }
  }

  &-link {
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
    text-decoration: none;
  }

  &--inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* deleted messages */
  &--deleted {
    background: var(--grey);
    float: left;
    padding: 8px 16px;
    border-radius: var(--border-radius);
    font-size: var(--sm-font);
    color: var(--darken-grey);
    margin: 0 0 0 42px;
  }

  /* error messages */
  &--error {
    margin: 0 0 32px 0;
    font-size: var(--sm-font);

    .str-chat__message-text-inner {
      background: rgba(208, 2, 27, 0.1);
      border: 1px solid rgba(208, 2, 27, 0.1);
    }
  }

  &--right {
    &.str-chat__message-commerce {
      justify-content: flex-end;
      margin-left: 0;
      .str-chat__message-commerce__actions {
        justify-content: flex-end;
      }
      .str-chat__message-commerce {
        &-inner {
          > .str-chat__message-commerce__actions {
            float: left;
            .str-chat__reaction-list {
              left: 46px;
            }
          }
          > .str-chat__message-commerce-reply-button {
            display: flex;
            justify-content: flex-end;
            .str-chat__message-replies-count-button {
              display: flex;
              flex-direction: row-reverse;
              svg {
                transform: scaleX(-1);
                margin-left: 5px;
                margin-bottom: 4px;
                margin-right: 0;
              }
            }
          }
        }
      }

      .str-chat__message-commerce-text-inner {
        background: #ebebeb;
        border-width: 0px;
        margin-top: 2px;
        border-color: transparent;
        p {
          text-align: right;
        }
      }
      > .str-chat__avatar {
        display: none;
      }

      .str-chat__message-attachment {
        margin: 0 auto 0 30px;
        &--img {
          border-radius: var(--border-radius) var(--border-radius) 2px var(--border-radius);
        }
      }
      .str-chat__message-attachment-card {
        border-radius: 16px 16px 4px 16px;
      }

      &--bottom,
      &--single {
        margin-right: 0;
      }
      &--single {
        .str-chat__message-commerce {
          &-text {
            &-inner {
              border-radius: 16px 16px 4px 16px;
              &--has-attachment {
                border-radius: 16px 4px 4px 16px;
              }
            }
          }
        }
      }
      &--bottom {
        .str-chat__message-commerce {
          &-text {
            &-inner {
              border-radius: 4px 4px 4px 16px;
            }
          }
        }
      }
      .str-chat__avatar {
        order: 1;
      }
      .str-chat__message-commerce-text {
        flex-direction: row-reverse;
        justify-content: flex-start;
        &-inner {
          flex: unset;
        }
      }
      .str-chat__message-commerce-data {
        text-align: right;
      }
    }
  }

  &--has-text {
    .str-chat__message-commerce {
      &-inner {
        .str-chat__message-attachment {
          width: 100%;
          height: auto;
          margin: 4px auto;
        }
      }
    }
  }
}

.str-chat {
  &__message-commerce {
    font-family: var(--second-font);
    &__actions {
      display: flex;
      margin-top: 5px;
      align-items: flex-start;
      justify-content: flex-start;
      min-width: 30px;

      &__action {
        margin: 5px;
        display: flex;
        align-items: center;
        height: 10px;
        cursor: pointer;
        svg {
          fill: #000;
          opacity: 0.5;
        }
        &:hover {
          svg {
            opacity: 1;
          }
        }
        &--thread,
        &--reactions {
          display: none;
        }
        &--options {
          position: relative;
          display: none;
          .str-chat__message-actions-box {
            bottom: 10px;
            left: unset;
            right: 100%;
            width: 120px;
            border-radius: 16px 16px 2px 16px;
          }
        }
      }
    }

    &:hover {
      .str-chat__message-commerce__actions__action--thread {
        display: flex;
      }
      .str-chat__message-commerce__actions__action--reactions {
        display: flex;
      }
    }

    &-text {
      display: flex;
      padding: 0 0 0 0;
      position: relative;
    }

    &-text-inner {
      text-align: left;

      &.str-chat__message-commerce-text-inner--is-emoji {
        margin: 5px 0;
        background: transparent;
      }

      p {
        text-align: left;
      }
      a {
        color: var(--secondary-color);
        font-weight: var(--font-weight-bold);
        text-decoration: none;
      }
      blockquote {
        margin: 0 0 0 5px;
        font-style: italic;
        padding-left: 20px;
        position: relative;

        &::before {
          font-size: var(--xxl-font);
          content: '\201C';
          font-style: italic;
          position: absolute;
          opacity: 0.5;
          top: 2px;
          left: -5px;
        }
      }
    }

    &:hover {
      .str-chat__message-commerce__actions__action--options {
        display: flex;
      }
      .str-chat__message-commerce__actions__action--reactions {
        display: flex;
      }
      .str-chat__message-commerce__actions__action--thread {
        display: flex;
      }
    }
  }
}

.str-chat__commerce-message--error-message {
  text-align: left;
  text-transform: uppercase;
  font-size: var(--xs-font);
  opacity: 0.5;
}

.str-chat__message-commerce-status {
  margin: 10px 0px 10px 10px;
  order: 3;
  position: absolute;
  left: 100%;
  bottom: 0;
  line-height: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 11;
  &-number {
    font-size: var(--xs-font);
    margin-left: 4px;
    position: absolute;
    left: 100%;
    color: rgba(0, 0, 0, 0.6);
  }

  > .str-chat__avatar {
    align-self: flex-end;
    margin-right: 0;
  }

  > .str-chat__tooltip {
    display: none;
    max-width: 300px;
    min-width: 100px;
    text-align: center;
  }

  &:hover {
    > .str-chat__tooltip {
      display: block;
    }
  }

  &::after {
    position: absolute;
    bottom: 100%;
    right: 0;
    content: ' ';
    width: 15px;
    height: 15px;
  }
}

.str-chat__message-commerce {
  .str-chat__message-attachment {
    width: calc(100% - 30px);
    max-width: unset;
    border-radius: unset;
    margin: 0 auto 0 0;
  }
  .str-chat__message-attachment-card {
    margin: 0;
    border-radius: 4px var(--border-radius) 4px 4px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.08);

    &--content {
      background: #ebebeb;
    }

    &--text {
      display: none;
    }
  }
}

.str-chat__list--thread {
  .str-chat__message-commerce {
    &__actions {
      width: 30px;
    }
    &__actions__action--options {
      .str-chat__message-actions-box {
        right: unset;
        left: 100%;
        border-radius: var(--border-radius) var(--border-radius) var(--border-radius) 2px;
      }
    }
  }
}

.str-chat.dark {
  .str-chat__message-commerce {
    &-data {
      color: white;
      opacity: 0.5;
    }
    &-text-inner {
      background: rgba(255, 255, 255, 0.05);
      color: white;
    }
    &__actions {
      svg {
        fill: white;
      }
    }

    .str-chat__message-attachment-card {
      background: rgba(0, 0, 0, 0.2);
      &--content {
        background: rgba(0, 0, 0, 0.2);
      }
      &--title {
        color: white;
      }
      &--url {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    &--right {
      .str-chat__message-commerce {
        &-text-inner {
          background: rgba(0, 0, 0, 0.2);
        }
        .str-chat__message-attachment-card {
          background: rgba(0, 0, 0, 0.2);
          &--content {
            background: rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}
