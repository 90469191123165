@import "~antd/dist/antd.css";

.ant-layout-header {
  background-color: transparent;
  padding: 0;
}

.ant-page-header-heading-left {
  margin-top: 0;
}

.ant-breadcrumb ~ .ant-page-header-heading {
  .ant-page-header-heading-left {
    margin-bottom: 0;
  }
}

.ant-menu {
  background: transparent;
}

.ant-layout {
  height: 100%;
}

/* there is no way to disable programaticaly next-year buttons in date pickers so they will be hidden */
/* hide year arrows to prevent accidental issues */
a.ant-calendar-next-year-btn {
  display: none !important;
}

a.ant-calendar-prev-year-btn {
  display: none !important;
}

.ant-image-preview-img {
  display: inline;
}