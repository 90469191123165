.str-chat__tooltip {
  position: absolute;
  right: 0;
  bottom: calc(100% + 10px);
  display: flex;
  background: var(--black);
  border-radius: var(--border-radius-sm);
  padding: var(--xxs-p) var(--xs-p);
  color: var(--white);
  font-size: var(--xs-font);
  max-width: 300px;

  button {
    outline: none;
    outline: 0;
    background: none;
    color: var(--primary-color);
    font-size: var(--xs-font);
    font-family: var(--second-font);
    border: none;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    right: 5px;
    width: 5px;
    height: 5px;
    background: var(--black);
    transform: rotate(45deg);
  }
}
