.channel-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 60px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 7px 9px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03);
  transform: matrix(1, 0, 0, 1, 0, 0);
  border-radius: 10px 10px 0 0;

  &__back-arrow {
    padding-left: 0.5rem;
  }

  &__name {
    flex: 1;
    padding-left: 1.5rem;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__right {
    display: flex;
    justify-self: flex-end;
    align-items: center;
    margin-right: 16px;
  }
}
