.dentists-list {
  &__row {
    border: 1px solid blue;

    &.ant-pro-list-row {
      padding: 0;
    }

    &--vertical {
      padding: 0 !important;
    }

    &--horizontal {
    }

    //.ant-pro-list-row {
    //  padding: 24px;
    //}
  }
}
