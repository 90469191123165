.str-chat__typing-indicator {
  display: flex;
  visibility: hidden;
  align-items: center;

  &--typing {
    visibility: visible;
  }

  &__avatars {
    display: flex;

    .str-chat__avatar {
      margin-right: calc(var(--xs-m) * -1);

      &:last-of-type {
        margin-right: var(--xs-m);
      }
    }
  }

  &__dots {
    position: relative;
    background: var(--white);
    border: 1px solid var(--black10);
    padding: var(--xxs-p) var(--xs-m);
    border-radius: var(--border-radius) var(--border-radius) var(--border-radius) 2px;
  }

  &__dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: var(--border-radius-round);
    margin-right: var(--xxs-m);
    background: var(--primary-color);
    animation: wave 1.1s linear infinite;

    &:nth-child(2) {
      animation-delay: -0.9s;
      opacity: 0.5;
    }

    &:nth-child(3) {
      animation-delay: -0.8s;
      opacity: 0.2;
    }
  }
}

.dark.str-chat {
  .str-chat__typing-indicator {
    &__avatars {
      .str-chat__avatar {
        border-color: var(--dark-grey);
      }
    }

    &__dots {
      background: var(--white5);
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-8px);
  }
}
