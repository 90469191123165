.str-chat__date-separator {
  display: flex;
  padding: var(--xl-p);
  align-items: center;

  &-date {
    font-size: var(--md-font);
    font-weight: var(--font-weight-bold);
    color: var(--overlay-dark);
    font-family: var(--second-font);
  }

  &-line {
    flex: 1;
    background-color: var(--overlay);
    height: 1px;
    border: none;
  }

  > * {
    &:not(:last-child) {
      margin-right: var(--sm-m);
    }
  }
}

.commerce.str-chat {
  .str-chat__date-separator {
    padding: var(--xl-p) 0;
  }
}

.dark.str-chat {
  .str-chat__date-separator {
    &-line {
      background-color: var(--grey-gainsboro);
    }

    &-date {
      color: var(--grey-whisper);
    }
  }

  &.team {
    .str-chat__date-separator {
      &-line {
        background-color: var(--overlay);
      }
    }
  }
}
