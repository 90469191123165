.eventsWrapper {
  margin: 20px 0 0 0;
}

.eventDate {
  margin-bottom: 4px;
  font-size: 14px;

  span {
    margin-left: 10px;
  }
}

.eventAuthor {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
}

.eventText {
  margin-bottom: 4px;
}
