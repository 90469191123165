.str-chat__actions-box {
  background: var(--white);
  background-image: linear-gradient(-180deg, var(--bg-gradient-start), var(--bg-gradient-end));
  box-shadow: 0 1px 0 0 var(--border);
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  position: absolute;
  min-width: 150px;

  &--right {
    right: 0;
    top: calc(100% + 2px);
  }

  &--left {
    left: 0;
    top: calc(100% + 2px);
  }

  > button {
    text-align: left;
    width: 100%;
    border: none;
    margin: 0;
    padding: var(--xs-p);
    font-size: var(--sm-font);
    background: none;
    cursor: pointer;

    &:not(:last-of-type) {
      box-shadow: 0 1px 0 0 var(--border);
    }

    &:hover {
      color: var(--primary-color);
    }
  }
}

.dark.str-chat {
  .str-chat__message-actions-box {
    background: var(--grey);
    background-image: linear-gradient(-180deg, var(--bg-gradient-start), var(--bg-gradient-end));
    box-shadow: 0 0 2px 0 var(--border), 0 1px 0 0 var(--border), 0 1px 8px 0 var(--border);

    button {
      color: var(--white);

      &:hover {
        color: var(--primary-color);
      }
    }
  }
}
