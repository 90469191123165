.str-chat__header {
  display: flex;
  padding: var(--xs-p);
  border-bottom: 1px solid var(--border);

  &-livestream {
    padding: var(--xs-p) var(--xl-p);
    min-height: 70px;
    display: flex;
    align-items: center;
    font-family: var(--second-font);
    background: var(--white);
    box-shadow: 0 1px 1px 0 var(--border);

    &-left {
      flex: 1;
      font-size: var(--md-font);

      &--title {
        font-weight: var(--font-weight-bold);
        margin: 0;
      }

      &--members {
        font-weight: var(--font-weight-regular);
        margin: 0;
      }

      &--livelabel {
        position: relative;
        left: 5px;
        font-size: var(--sm-font);
        text-transform: uppercase;
        color: var(--red);
        display: inline-block;
        animation: pulse 2s infinite;

        &::before {
          content: '';
          position: relative;
          top: -2px;
          left: -4px;
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: var(--border-radius-round);
          background-color: var(--accent_red);
        }

        @keyframes pulse {
          0% {
            opacity: 0.5;
          }

          50% {
            opacity: 1;
          }

          100% {
            opacity: 0.5;
          }
        }
      }
    }

    &-right {
      display: flex;
      margin: 0 calc(var(--xxs-m) * -1);

      &-button-wrapper {
        position: relative;
      }
    }
  }
}

.str-chat__header .str-chat__avatar {
  margin: 0 var(--sm-m) 0 0;
}

.str-chat__title {
  font-weight: var(--font-weight-semi-bold);
}

.str-chat__meta {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.str-chat__info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: var(--md-font);
  color: var(--grey);
}

.str-chat__square-button {
  background: var(--grey-whisper);
  border: 1px solid var(--border);
  border-radius: var(--border-radius-sm);
  padding: var(--xs-p);
  margin: 0 var(--xxs-m);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: var(--black);
  }

  &:active {
    background-color: var(--grey-whisper);
  }
}

.dark.str-chat {
  .str-chat__square-button {
    background: var(--white5);
    box-shadow: 0 1px 0 0 var(--black70);
    border-radius: var(--border-radius-sm);

    svg {
      fill: var(--white70);
    }
  }
}

.messaging {
  &.str-chat {
    .str-chat {
      &__header-livestream {
        position: relative;
        z-index: 1;
        border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
        background: var(--white-snow);
        box-shadow: 0 7px 9px 0 var(--border), 0 1px 0 0 var(--border);
      }

      @media screen and (max-width: 960px) {
        &__header-livestream {
          padding-left: var(--sm-p);
          padding-right: var(--sm-p);
        }
      }
    }

    &.dark {
      .str-chat__header-livestream {
        background: var(--dark-grey);
        box-shadow: 0 7px 9px 0 var(--border), 0 1px 0 0 var(--border);
        border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
        color: var(--white);
      }
    }
  }
}

.livestream {
  &.str-chat {
    .str-chat {
      &__header-livestream {
        position: relative;
        z-index: 1;
        background: var(--grey-whisper);
        box-shadow: 0 1px 1px 0 var(--border);
      }
    }

    &.dark {
      .str-chat__header-livestream {
        background: var(--white5);
        box-shadow: 0 1px 1px 0 var(--border);

        &-left {
          &--title,
          &--members {
            color: var(--white);
          }

          &--title {
            font-size: var(--lg-font);
          }
        }
      }
    }
  }
}

.commerce {
  &.str-chat {
    .str-chat {
      &__header-livestream {
        background: var(--white80);
        box-shadow: 0 7px 9px 0 var(--black5), 0 1px 0 0 var(--black5);
        border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
        padding: var(--md-p);

        &-left {
          &--title {
            font-size: var(--xxl-font);
            margin: 0;
            line-height: 1;
            font-weight: var(--font-weight-regular);
          }

          &--subtitle {
            margin: var(--xs-m) 0;
            font-size: var(--lg-font);
          }

          &--members {
            display: none;
          }
        }

        &-right {
          &-button {
            &--info {
              display: none;
            }
          }
        }
      }
    }

    &.dark {
      .str-chat__header-livestream {
        background: var(--grey);
        box-shadow: 0 7px 9px 0 var(--black5), 0 1px 0 0 var(--black5);
        border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
        color: var(--white);
      }
    }
  }
}

.team {
  &.str-chat {
    &.dark {
      .str-chat__header-livestream {
        background: var(--dark-grey);
        box-shadow: 0 1px 1px 0 var(--black30);

        &-left {
          color: var(--white);

          &--title {
            font-size: var(--lg-font);
          }

          &--members {
            font-size: var(--sm-font);
          }
        }
      }
    }
  }
}

.str-chat__header-hamburger {
  width: 30px;
  height: 38px;
  padding: var(--xs-p) var(--xs-p) var(--xs-p) 0;
  margin-right: var(--xs-m);
  display: none;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;

  &:hover {
    .str-chat__header-hamburger--line {
      background: var(--primary-color);
    }
  }

  @media screen and (max-width: 960px) {
    display: flex;
  }
}

.str-chat__header-hamburger--line {
  width: 100%;
  height: 2px;
  background: var(--primary-color);
  border-radius: var(--border-radius-sm);
}
