.container {
  margin-bottom: 12px;

  &.edit {
    :global(li.ant-list-item) {
      display: block;
    }

    :global(ul.ant-list-item-action) {
      margin-top: 20px;
      margin-left: 8px;
    }
  }
}
