.str-chat__channel-search {
  padding: var(--md-p) var(--md-p) 0;
  display: flex;
  align-items: center;
  position: relative;
  background: var(--grey-gainsboro);

  input {
    flex: 1;
    background: var(--grey-whisper);
    border: 1px solid transparent;
    outline: none;
    height: 30px;
    border-radius: var(--border-radius);
    color: var(--black);
    font-size: var(--md-font);
    padding: var(--sm-p);

    &::placeholder {
      color: var(--grey);
    }

    &:focus {
      background: var(--white);
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 2px var(--primary-color-faded);
      transition: all 0.2s ease-in-out;
    }
  }

  &-container {
    position: absolute;
    width: 300px;
    z-index: 2;
    padding: 8px;

    &-searching {
      padding-left: 8px;
    }

    &-empty {
      padding-left: 8px;
    }

    .str-chat__channel-search-result {
      display: flex;
      align-items: center;
      border-radius: var(--border-radius-md);
      height: 40px;
      cursor: pointer;
      padding-left: 8px;

      &:hover {
        font-weight: var(--font-weight-bold);
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &-result.focused {
    font-weight: var(--font-weight-bold);
  }

  &-container.popup {
    border-radius: var(--border-radius-md);
    background: var(--white-smoke);
    left: 300px;
    top: 20px;
    box-shadow: 0 7px 9px 0 var(--border), 0 1px 0 0 var(--border);
    border: 1px solid var(--border);

    .str-chat__channel-search-result {
      &:hover {
        background: var(--light-grey);
      }
    }

    .str-chat__channel-search-result.focused {
      background: var(--light-grey);
    }
  }

  &-container.inline {
    top: 64px;
    right: 0;
    background: var(--grey-gainsboro);
    height: calc(100vh - 60px);

    .str-chat__channel-search-result {
      &:hover {
        background: var(--white);
      }
    }

    .str-chat__channel-search-result.focused {
      background: var(--white);
    }
  }
}

.dark.str-chat {
  .str-chat__channel-search {
    input {
      background: var(--grey-gainsboro);
      color: var(--white);
    }
  }
}
