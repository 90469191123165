.str-chat__edit-message-form {
  width: 100%;

  form {
    position: relative;
    width: 100%;
  }

  textarea {
    padding: 8px;
    background: var(--white);
    box-shadow: inset 0 0 0 1px var(--primary-color);
    border: 1px solid transparent;
    resize: none;
    border-radius: var(--border-radius-sm);
    width: 100%;
    font-size: var(--lg-font);
    line-height: 22px;
    color: var(--black);

    &:focus {
      box-shadow: inset 0 0 0 1px var(--primary-color), 0 0 0 2px var(--primary-color-faded);
      outline: 0;
    }
  }

  .str-chat__message-team-form-footer {
    button {
      background: none;
      border: none;
      font-weight: var(--font-weight-bold);
      color: var(--grey);
      padding: 2px 6px;

      &[type='submit'] {
        color: var(--primary-color);
      }
    }
  }

  .rfu-dropzone {
    width: 100%;
  }

  .rfu-file-upload-button,
  .str-chat__input-emojiselect,
  .str-chat__input-fileupload {
    display: flex;
    position: unset;
    top: unset;
    right: unset;
    left: unset;

    svg {
      fill: var(--black);
      opacity: 0.5;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
}

.str-chat__edit-message-form-options {
  display: flex;
  align-items: baseline;
}

.str-chat__edit-message-form-options .str-chat__fileupload-wrapper:hover .str-chat__tooltip {
  display: none;
}

.str-chat.dark {
  .str-chat__edit-message-form {
    .rfu-file-upload-button,
    .str-chat__input-emojiselect {
      svg {
        fill: var(--grey-gainsboro);
      }
    }

    .str-chat__message-team-form-footer {
      button {
        color: var(--grey-gainsboro);

        &[type='submit'] {
          color: var(--primary-color);
        }
      }
    }

    textarea {
      background: var(--white5);
      box-shadow: 0 0 0 1px var(--black20);
      border: 2px solid transparent;
      border-radius: var(--border-radius-sm);
      color: var(--white);

      &:focus {
        box-shadow: inset 0 0 0 1px var(--primary-color);
        border: 2px solid var(--primary-color-faded);
        border-radius: var(--border-radius-sm);
      }
    }
  }
}

.str-chat__edit-message-form {
  position: relative;
}
