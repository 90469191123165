.channel-preview__container {
  height: 56px;
  margin-bottom: 8px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;

  &:hover {
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
    transition: background 0.1s ease-in-out;
  }
}

.str-chat.dark .channel-preview__container:hover {
  background: #2c2c30;
}

.channel-preview__container.selected {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  transition: background 0.1s ease-in-out;
}

.str-chat.dark .channel-preview__container.selected {
  background: #2c2c30;
}

.channel-preview__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
}

.channel-preview__content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 18px;
  margin-bottom: 4px;
}

.channel-preview__content-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channel-preview__unread-count {
  background-color: #e5e7eb;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.channel-preview__content-name {
  font-family: Helvetica Neue, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  margin: 0;
  max-width: 184px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.str-chat.dark .channel-preview__content-name {
  color: #ffffff;
}

.channel-preview__content-time {
  font-family: Helvetica Neue, sans-serif;
  font-size: 11px;
  color: #858688;
  margin: 0;
}

.channel-preview__content-message {
  font-family: Helvetica Neue, sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  color: #858688;
  height: 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channel-preview__container .str-chat__avatar {
  margin-right: 0;
}

.channel-preview__avatars {
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 20px;
  overflow: hidden;

  &.two {
    div {
      &:first-child {
        position: relative;
        right: 10px;
      }

      &:nth-child(2) {
        position: relative;
        right: 30px;
      }
    }

    span {
      width: 20px;
      overflow: hidden;
    }
  }

  &.three span {
    width: 20px;
    overflow: hidden;
  }
}
