.str-chat__down {
  display: flex;
  height: 100%;

  &-main {
    flex: 1;
    padding: var(--lg-p);
  }
}

.dark.str-chat {
  .str-chat__down {
    color: var(--white);
  }
}
