.str-chat__messaging-input {
  background: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  padding: 8px;
  position: relative;
}

.messaging-input__input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  height: fit-content;
  z-index: 100;
  border-radius: 20px;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.16);
  transition: border 0.2s ease-in-out;
}

.str-chat__messaging-input {
  .messaging-input__input-wrapper:focus-within {
    border: 2px solid #005fff;
  }

  > *:not(:first-of-type) {
    margin-left: 8px;
  }

  .rfu-file-upload-button {
    min-width: 35px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.str-chat__textarea {
  display: flex;
  align-items: center;

  textarea {
    background: #ffffff;
    font-size: 14px;
    line-height: 16px;
    min-height: 0px;
    transition: box-shadow 0.2s ease-in-out;
    color: rgba(0, 0, 0, 0.9);
    border: none !important;
    outline: none !important;
    border-radius: 20px;
  }
}

.str-chat__textarea textarea:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.messaging-input__button {
  opacity: 0.5;
  cursor: pointer;
  padding: 0 4px;
  transition: opacity 0.2s ease-in-out;

  svg path {
    transition: fill 0.2s ease-in-out;
    fill: black;
  }

  &:hover {
    opacity: 1;

    svg path {
      fill: #005fff !important;
    }
  }
}

.str-chat__input--emojipicker {
  z-index: 100;
  left: 36px;
}

.str-chat__thread .str-chat__input--emojipicker {
  position: fixed;
  top: 25% !important;
  right: 330px;
  left: auto;
}

.str-chat__messaging-input {
  .emoji-mart-bar,
  .emoji-mart-search input {
    border-color: rgba(0, 0, 0, 0.36);
  }
}

.giphy-icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 24px;
  width: 63px;
  background: #005fff;
  border-radius: 12px;
  margin-left: 8px;
}

.giphy-icon__text {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 11px;
  line-height: 8px;
  color: #ffffff;
}

div.rfu-dropzone {
  width: 100%;

  &:focus {
    outline: none;
  }
}

.rfu-image-previewer {
  flex: none;
  margin-left: 12px;
}

.rfu-image-previewer__image {
  margin-bottom: 0;
}

div.rta__autocomplete.str-chat__emojisearch {
  z-index: 10;
  position: absolute;
  width: 30%;
  background: #fafafa;
  margin: 4px 10px;
  border-radius: 16px !important;
}

.str-chat__user-item {
  background: #ffffff;
  color: #000000;
}

.str-chat__user-item:hover {
  background: #005fff !important;
  color: #ffffff;
  cursor: pointer;
}

.rta__entity--selected {
  background: #005fff;
}

.str-chat__slash-command:hover {
  background: #005fff;
  cursor: pointer;
}

.rta__list-header {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.9);
  mix-blend-mode: normal;
}

@media screen and (max-width: 640px) {
  div.rta__autocomplete.str-chat__emojisearch {
    width: unset;
  }

  .str-chat__textarea textarea {
    font-size: 16px;
  }
}

.messaging-input {
  &__input-wrapper {
    .rfu-image-previewer {
      flex: none;
      margin-left: 12px;
    }
  }
}
