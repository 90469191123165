.order-form {
  &__responsive {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    column-gap: 1.5rem;
  }

  &__patient-responsive {
    flex: 1 1 200px;
  }

  &__container {
    &--size-small {
      display: grid;
      grid-template:
        "basic-info" auto
        "order-items" auto
        "order-drawing" auto
        "order-color" auto
        "order-notes" auto
        "submit-order" auto / 1fr;
    }

    &--size-large {
      display: grid;
      grid-template:
        "basic-info     basic-info" auto
        "order-items    order-drawing" auto
        "order-color    order-notes" auto
        "submit-order   submit-order" auto
        / 1fr 1fr;
    }
  }

  &__basic-info {
    grid-area: basic-info;
  }

  &__order-items {
    grid-area: order-items;
  }

  &__order-drawing {
    grid-area: order-drawing;
  }

  &__order-color {
    grid-area: order-color;
  }

  &__order-notes {
    grid-area: order-notes;
  }

  &__submit-order {
    grid-area: submit-order;
  }

  &__basic-info-inner {
    display: flex;
    flex-flow: row wrap;
  }
}
