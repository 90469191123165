.container {
  display: grid;
  padding-top: 15px;

  &.small {
    grid-template:
      "menu" auto
      "view" auto
      / 1fr;

    .view {
      padding: 15px;
    }
  }

  &.medium {
    grid-template:
      "menu" auto
      "view" auto
      / 1fr;

    .view {
      padding: 20px 30px 30px 30px;
    }
  }

  &.large {
    grid-template: "menu view" auto / 224px 1fr;

    .view {
      padding: 20px 40px 40px 40px;
    }
  }
}

.menu {
  grid-area: menu;
}

.view {
  grid-area: view;
  padding: 40px;
}
