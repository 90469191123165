@import "~@uppy/core/src/style.scss";
@import "~@uppy/dashboard/src/style.scss";
@import "~@uppy/audio/src/style.scss";
@import "~@uppy/webcam/src/style.scss";
@import "~@uppy/screen-capture/src/style.scss";

.uppy-Dashboard-Item {
  .uppy-size--md & {
    float: left;
  }
}
