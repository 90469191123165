/* fonts */
$main-font: 'IBM Plex Sans', sans-serif;
$second-font: 'Helvetica Neue', Helvetica, Arial, sans-serif;

$base-font-weight: 400;
$heavy-font-weight: 700;

/* colors */
$primary-color: #ff00ff;
$secondary-color: #006cff;

$dt-bg-team: #1d1f22;

$border-color: #00000014;

$black: #000000;
$white: #ffffff;
$grey: #f0f0f0;
$darken-grey: #343434;
$lighten-black: #808080;
$lighten-grey: #858585;

/* miscellaneous */
$assetsPath: '../assets';
$border-radius: 16px;

:root {
  /* fonts */
  --main-font: #{$main-font};
  --second-font: #{$second-font};

  /* font size */

  --xs-font: 10px;
  --sm-font: 12px;
  --md-font: 14px;
  --lg-font: 16px;
  --xl-font: 22px;
  --xxl-font: 26px;
  --xxxl-font: 32px;

  /* fonts weight */
  --font-weight-regular: #{$base-font-weight}; //400
  --font-weight-semi-bold: 600;
  --font-weight-bold: #{$heavy-font-weight}; //700

  /* colors */
  --primary-color: #{$secondary-color}; // this reassignment is to maintain backwards compatibility with the old scss variables
  --primary-color-faded: #006cff5c;

  --magenta: #{$primary-color};
  --red: #ff0000;
  --faded-red: #d0021b1a;

  --dt-bg-team: #{$dt-bg-team};

  --border-color: #{$border-color};

  --lighten-black: #{$lighten-black};

  --lighten-grey: #{$lighten-grey};

  --light-grey: #ebebeb;
  --grey: #{$lighten-black};
  --dark-grey: #{$darken-grey};

  --green: #28ca42;
  --faded-green: #02d0021a;

  //-------------------------------White
  --white: #{$white};
  --white5: #ffffff0d;
  --white10: #ffffff1a;
  --white20: #ffffff33;
  --white30: #ffffff4d;
  --white40: #ffffff66;
  --white50: #ffffff80;
  --white60: #ffffff99;
  --white70: #ffffffb3;
  --white80: #ffffffcc;
  --white90: #ffffffe6;
  --white95: #fffffff2;

  //-------------------------------Black
  --black: #{$black};
  --black5: #0000000d;
  --black10: #0000001a;
  --black20: #00000033;
  --black30: #0000004d;
  --black40: #00000066;
  --black50: #00000080;
  --black60: #00000099;
  --black70: #000000b3;
  --black80: #000000cc;
  --black90: #000000e6;
  --black95: #000000f2;

  /* border-radius */
  --border-radius: #{$border-radius};
  --border-radius-sm: calc(var(--border-radius) / 4);
  --border-radius-md: calc(var(--border-radius) / 2);
  --border-radius-round: 999px;

  --spacing-unit: 8px; // .5rem

  /* padding */
  --xxs-p: calc(var(--spacing-unit) / 2); // 4px
  --xs-p: var(--spacing-unit); // 8px
  --sm-p: calc(var(--spacing-unit) * 2); // 16px Base
  --md-p: calc(var(--spacing-unit) * 3); // 24px
  --lg-p: calc(var(--spacing-unit) * 4); // 32px
  --xl-p: calc(var(--spacing-unit) * 5); // 40px
  --xxl-p: calc(var(--spacing-unit) * 8); // 64px

  /* margin */
  --xxs-m: calc(var(--spacing-unit) / 2); // 4px
  --xs-m: var(--spacing-unit); // 8px
  --sm-m: calc(var(--spacing-unit) * 2); // 16px Base
  --md-m: calc(var(--spacing-unit) * 3); // 24px
  --lg-m: calc(var(--spacing-unit) * 4); // 32px
  --xl-m: calc(var(--spacing-unit) * 5); // 40px
  --xxl-m: calc(var(--spacing-unit) * 8); // 64px

  /* miscellaneous */
  --assetsPath: '../assets';

  /* accent colors */
  --accent_blue: #005fff;
  --accent_green: #20e070;
  --accent_red: #ff3742;

  /* base colors */

  --bg-gradient-end: #f7f7f7;
  --bg-gradient-start: #fcfcfc;
  --black: #000000;
  --blue-alice: #e9f2ff;
  --border: #00000014; // 14 = 8% opacity; top: x=0, y=-1; bottom: x=0, y=1
  --button-background: #ffffff;
  --button-text: #005fff;
  --grey: #7a7a7a;
  --grey-gainsboro: #dbdbdb;
  --grey-whisper: #ecebeb;
  --grey-2: #f2f2f2;
  --highlight: #fbf4dd;
  --modal-shadow: #00000099; // 99 = 60% opacity; x=0, y= 1, radius=4
  --overlay: #00000033; // 33 = 20% opacity
  --overlay-dark: #00000099; // 99 = 60% opacity
  --shadow-icon: #00000040; // 40 = 25% opacity; x=0, y=0, radius=4
  --targetedMessageBackground: #fbf4dd; // dark mode = #302D22
  --transparent: transparent;
  --white: #ffffff;
  --white-smoke: #f2f2f2;
  --white-snow: #fcfcfc;

  --modal-overlay-color: rgba(0, 0, 0, 0.89);
}
