.str-chat__modal-image__wrapper {
  line-height: 0;
  position: relative;
  text-align: center;
  box-sizing: border-box;
}

.str-chat__modal-image__image {
  max-height: 100vh;
  max-width: 100%;
  display: inline;
}
