.str-chat__audio {
  &__wrapper {
    height: 80px;
    overflow: hidden;
    position: relative;
    border-radius: var(--border-radius-sm);
    margin: 0;
    display: flex;
    background: var(--grey-whisper);
  }

  &__image {
    height: 80px;
    width: 80px;
    position: relative;
    z-index: 20;

    &--overlay {
      width: inherit;
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--overlay-dark);
      z-index: 30;
      font-size: 3em;
      color: var(--white-smoke);
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
    }

    &--button {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;

      svg {
        fill: var(--white-smoke);
      }
    }

    img {
      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: var(--xs-p) var(--sm-p);
    margin-left: var(--sm-m);
    width: 100%;

    &--title {
      margin: 0;
      padding: 0;
      line-height: 1;
    }

    &--subtitle {
      margin: 0;
      padding: 0;
      line-height: 1;
      font-size: var(--sm-font);
      opacity: 0.49;
    }

    &--progress {
      height: 6px;
      width: 100%;
      border-radius: var(--border-radius-sm);
      background: var(--overlay);
      padding: 1px;
      margin: calc(var(--xxs-m) / 2) 0; //TODO-CSS

      > div {
        height: 4px;
        border-radius: var(--border-radius-sm);
        width: 0%;
        background: var(--primary-color);
        transition: width 0.5s linear;
      }
    }
  }
}

.str-chat.dark {
  .str-chat__audio {
    &__wrapper {
      background: var(--black);
      color: var(--white);
    }

    &__content {
      &--progress {
        background: var(--grey);
      }
    }
  }
}
