.graphical-anchor-picker {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  margin: 10px;

  &__color-indicator {
    width: 100%;
    height: 8px;
    padding: 4px;
  }
}