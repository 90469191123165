@import "../variables/colors";

.ant-list-item {
  &.hover-effect {
    cursor: pointer;
    transition: background-color .3s ease-in-out;
    background-color: transparent;

    &:hover {
      background-color: $ant-blue-0;
    }
  }
}

.ant-list-item-meta.centered {
  align-items: center;

  .ant-list-item-meta-title {
    margin-bottom: 0;
  }

  .ant-list-item-meta-description {
    margin-top: 4px;
  }
}