.header {
  padding: 0;
  background: #fff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &__menu-toggle {
    min-width: 4rem;
  }

  &__user-menu {
    display: flex;
    align-content: center;
    justify-items: center;
    min-width: 4rem;
  }

  .ant-menu-horizontal {
    border-bottom: none;
  }
}
