.str-chat.messaging {
  .str-chat__event-component__channel-event {
    display: flex;
    margin-top: var(--md-m);

    &__content {
      margin-right: var(--xs-m);
      color: var(--overlay-dark);
      font-size: var(--lg-font);
    }

    &__date {
      font-size: var(--xs-font);
      margin-top: var(--xxs-m);
    }
  }
}

.str-chat.team {
  .str-chat__event-component__channel-event {
    display: flex;
    margin: var(--md-m) var(--xl-m);

    &__content {
      margin-right: var(--xs-m);
      color: var(--overlay-dark);
      font-size: var(--lg-font);
    }

    &__sentence {
      margin-right: var(--xs-m);
      color: var(--overlay-dark);
      font-size: var(--lg-font);
    }

    &__date {
      font-size: var(--xs-font);
      margin-top: var(--xxs-m);
    }
  }
}

.str-chat.commerce,
.str-chat.livestream {
  .str-chat__event-component__channel-event {
    display: none;
  }
}
