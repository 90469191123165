.str-chat__channel-list {
  flex: 1;
  overflow-y: auto;
  max-width: 300px;
  background: var(--blue-alice);
  box-shadow: 1px 0 0 0 var(--black5);
  display: flex;
  flex-direction: column;

  &--channels {
    flex: 1;
  }

  .channel_preview {
    padding: var(--xs-p) var(--sm-p);
    border-bottom: 1px solid var(--black10);
  }
}

.str-chat__button {
  background: var(--white);
  box-shadow: 0 1px 1px 0 var(--black10), 0 1px 4px 0 var(--black10);
  font-size: var(--md-font);
  padding: var(--sm-p) var(--xxl-p); //TODO-CSS 72px
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 10px);
  margin: var(--xxs-m);
  border: 1px solid transparent;

  &:active,
  &:focus {
    outline: none;
    box-shadow: 0 0 1px 0 var(--border), 0 1px 4px 0 var(--border),
      0 0 0 2px var(--primary-color-faded);
    border: 1px solid var(--primary-color);
  }

  > * {
    margin: 0 var(--xxs-m);
  }

  &--round {
    border-radius: var(--border-radius-round);
  }
}

.str-chat-channel-checkbox {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100001;
}

.str-chat-channel-list-burger {
  width: 10px;
  height: 50px;
  background: var(--white);
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  padding: var(--xxs-p);
  box-shadow: 0 4px 6px var(--border);
  position: fixed;
  top: 10px;
  left: 0;
  z-index: 10000;
  justify-content: center;
  cursor: pointer;

  div {
    width: 4px;
    height: 100%;
    border-radius: var(--border-radius-sm);
    background: var(--border);
  }
}

@media screen and (max-width: 960px) {
  .str-chat-channel-list-burger {
    display: flex;
  }

  .str-chat-channel-list {
    &.messaging,
    &.team,
    &.commerce {
      position: absolute;
      left: -100%;
      top: 0;
      z-index: 1001;
      min-height: 100vh;
      overflow-y: auto;
      box-shadow: 7px 0 9px 0 var(--black5), 1px 0 0 0 var(--black5);
      transition: left 200ms ease-in-out;
    }
  }

  .str-chat-channel-list--open {
    &.messaging,
    &.team,
    &.commerce {
      left: 0;
      width: 100%;
    }
  }
}

.str-chat-channel-list {
  .str-chat__channel-list-messenger {
    padding: 0;

    &__main {
      padding: var(--sm-p) var(--sm-p) 0 var(--sm-p);
      overflow-y: auto;
    }
  }
}
