.pricing-input {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-row-gap: 0.5em;
  grid-column-gap: 1em;
  align-items: center;
  margin-right: 1rem;
  justify-content: start;

  &--one-column {
    grid-template-columns: repeat(1, auto);
  }

  &--two-columns {
    grid-template-columns: repeat(2, auto);
  }

  &__label {
    display: flex;
    flex: 1 1 200px;
  }

  &__input {
    min-width: 300px;
  }
}
